/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { type ErrorInfo } from "react";
import { withTranslation } from "react-i18next";
import ErrorHandler from "../../pages/error-handler";
import "./style.less";

class ErrorBoundary extends React.Component<
  { children: any; t: any },
  { hasError: boolean; error: any; info: any }
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error, info });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorHandler />;
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
