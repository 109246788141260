import { useTranslation } from "react-i18next";
import ConstantKey from "../../../core/locale/strings.json";

export const AuthFarmImage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  return (
    <div className="welcome-text">
      <div className="welcome">{translate(ConstantKey.WEL)}</div>
      <div className="welcome" style={{ paddingLeft: "9%", marginTop: "3%" }}>
        {translate(ConstantKey.COME)}
      </div>
    </div>
  );
};

export default AuthFarmImage;
