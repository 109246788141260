import React from "react";

export const CreateOrganization = (): JSX.Element => {
  const workSpaceId = process.env.REACT_APP_WORKSPACE_ID ?? "";
  const createOrgDeepLink = `${
    process.env.REACT_APP_CROPWISE_APP_URL ?? ""
  }/deep-actions/organization?workspace_id=${workSpaceId}&callback_uri=${
    process.env.REACT_APP_URL
  }/deep-action`;

  React.useEffect(() => {
    window.location.href = createOrgDeepLink;
  }, [createOrgDeepLink]);

  return <>Redirecting to create organization....</>;
};

export default CreateOrganization;
