import requests from "./axios";

const ProductTemplateApi = {
  getTemplateDetails: async (uniqueId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/template-config/content/${uniqueId}`
    ),
  getBannerTemplateApi: async (countryCode: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/banners/?countryCode=${countryCode}`
    ),
  getUserRecommendations: async (
    email: string,
    start: number,
    size: number
  ): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/ddg/users/data?email=${email}&start=${start}&size=${size}`
    ),
  getPreCommitments: async (): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/commitment/sfdc`
    ),
  getCEHubSecurityKey: async (growerRefId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/cehub/identity?growerRefId=${growerRefId}`
    ),
};

export default ProductTemplateApi;
