import IN from "./india-flag.svg";
import PL from "./poland-flag.svg";
import US from "./usa-flag.svg";
import BR from "./brazil-flag.svg";
import AR from "./argentina-flag.svg";
import ES from "./spain-flag.svg";
import HU from "./hungary-flag.svg";
import FR from "./france-flag.svg";
import DE from "./germany-flag.svg";
import GB from "./gb-flag.svg";
export default {
  IN,
  PL,
  US,
  BR,
  AR,
  ES,
  HU,
  FR,
  DE,
  GB,
};
