import { isValidEmail } from "../../core/hook/useForm";
import {
  type SignUpDTO,
  type SignUpReducerActionDTO,
} from "../../core/types/authentication.dto";
import ConstantKey from "../../core/locale/strings.json";

export const initialState: SignUpDTO = {
  email: {
    value: "",
    required: true,
    validate: isValidEmail,
    validateMessage: ConstantKey.INVALID_EMAIL,
  },
  password: {
    value: "",
    required: true,
  },
  first_name: {
    value: "",
    required: true,
  },
  last_name: {
    value: "",
    required: true,
  },
  job_title: {
    value: "",
  },
  country_code: {
    value: "",
    required: true,
  },
  phone: {
    value: "",
  },
  tcAccept: {
    value: false,
    required: true,
  },
  pnAccept: {
    value: false,
    required: true,
  },
  locale: {
    value: "en",
  },
};

export const signUpReducer = (
  state: SignUpDTO,
  action: SignUpReducerActionDTO
): SignUpDTO => {
  switch (action.type) {
    case "EMAIL":
      return { ...state, email: { ...state.email, value: action.payload } };
    case "PASSWORD":
      return {
        ...state,
        password: { ...state.password, value: action.payload },
      };
    case "FIRST_NAME":
      return {
        ...state,
        first_name: { ...state.first_name, value: action.payload },
      };
    case "LAST_NAME":
      return {
        ...state,
        last_name: { ...state.last_name, value: action.payload },
      };
    case "JOB_TITLE":
      return {
        ...state,
        job_title: { ...state.job_title, value: action.payload },
      };
    case "COUNTRY_CODE":
      return {
        ...state,
        country_code: { ...state.country_code, value: action.payload },
      };
    case "PHONE":
      return { ...state, phone: { ...state.phone, value: action.payload } };
    case "TC_ACCEPT":
      return {
        ...state,
        tcAccept: { ...state.tcAccept, value: action.payload },
      };
    case "PN_ACCEPT":
      return {
        ...state,
        pnAccept: { ...state.pnAccept, value: action.payload },
      };
    case "RESET":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
