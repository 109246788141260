import type { SignInPayloadDTO } from "../types/authentication.dto";
import requests from "./axios";

const GrowerApi = {
  checkGrowerInfo: async (email: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/info?growerEmail=${email}`
    ),
  checkGrowerExist: async (growerRefId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/${growerRefId}/isExist`
    ),
  growerSignIn: async (payload: SignInPayloadDTO): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/signin`,
      payload
    ),
  growerSignUp: async (payload: any): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/signup`,
      payload
    ),
  addDefaultLocation: async (lat: number, lon: number): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/default-location?latitude=${lat}&longitude=${lon}`
    ),
  getCountriesList: async (): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/countries/?page=0&size=25&myfield-only=true`
    ),
};
export default GrowerApi;
