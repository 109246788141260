import cloud1 from "./cloud-1.svg";
import cloud2 from "./cloud-2.svg";
import cloud3 from "./cloud-3.svg";
import cloud4 from "./cloud-4.svg";
import cloud5 from "./cloud-5.svg";
import cloud6 from "./cloud-6.svg";
import cloud7 from "./cloud-7.svg";
import cloud8 from "./cloud-8.svg";
import cloud9 from "./cloud-9.svg";
import cloud10 from "./cloud-10.svg";
import cloud11 from "./cloud-11.svg";
import cloud12 from "./cloud-12.svg";
import cloud13 from "./cloud-13.svg";
import cloud14 from "./cloud-14.svg";
import cloud15 from "./cloud-15.svg";
import cloud16 from "./cloud-16.svg";
import cloud17 from "./cloud-17.svg";
import cloud18 from "./cloud-18.svg";
import cloud19 from "./cloud-19.svg";

import hourlyCloud1 from "./hourlyIcons/day/cloud-1.svg";
import hourlyCloud2 from "./hourlyIcons/day/cloud-2.svg";
import hourlyCloud3 from "./hourlyIcons/day/cloud-3.svg";
import hourlyCloud4 from "./hourlyIcons/day/cloud-4.svg";
import hourlyCloud5 from "./hourlyIcons/day/cloud-5.svg";
import hourlyCloud6 from "./hourlyIcons/day/cloud-6.svg";
import hourlyCloud7 from "./hourlyIcons/day/cloud-7.svg";
import hourlyCloud8 from "./hourlyIcons/day/cloud-8.svg";
import hourlyCloud9 from "./hourlyIcons/day/cloud-9.svg";
import hourlyCloud10 from "./hourlyIcons/day/cloud-10.svg";
import hourlyCloud11 from "./hourlyIcons/day/cloud-11.svg";
import hourlyCloud12 from "./hourlyIcons/day/cloud-12.svg";
import hourlyCloud13 from "./hourlyIcons/day/cloud-13.svg";
import hourlyCloud14 from "./hourlyIcons/day/cloud-14.svg";
import hourlyCloud15 from "./hourlyIcons/day/cloud-15.svg";
import hourlyCloud16 from "./hourlyIcons/day/cloud-16.svg";
import hourlyCloud17 from "./hourlyIcons/day/cloud-17.svg";
import hourlyCloud18 from "./hourlyIcons/day/cloud-18.svg";
import hourlyCloud19 from "./hourlyIcons/day/cloud-19.svg";
import hourlyCloud20 from "./hourlyIcons/day/cloud-20.svg";
import hourlyCloud21 from "./hourlyIcons/day/cloud-21.svg";
import hourlyCloud22 from "./hourlyIcons/day/cloud-22.svg";
import hourlyCloud23 from "./hourlyIcons/day/cloud-23.svg";
import hourlyCloud24 from "./hourlyIcons/day/cloud-24.svg";
import hourlyCloud25 from "./hourlyIcons/day/cloud-25.svg";
import hourlyCloud26 from "./hourlyIcons/day/cloud-26.svg";
import hourlyCloud27 from "./hourlyIcons/day/cloud-27.svg";
import hourlyCloud28 from "./hourlyIcons/day/cloud-28.svg";
import hourlyCloud29 from "./hourlyIcons/day/cloud-29.svg";
import hourlyCloud30 from "./hourlyIcons/day/cloud-30.svg";
import hourlyCloud31 from "./hourlyIcons/day/cloud-31.svg";
import hourlyCloud32 from "./hourlyIcons/day/cloud-32.svg";
import hourlyCloud33 from "./hourlyIcons/day/cloud-33.svg";
import hourlyCloud34 from "./hourlyIcons/day/cloud-34.svg";
import hourlyCloud35 from "./hourlyIcons/day/cloud-35.svg";

import hourlyNightCloud1 from "./hourlyIcons/night/cloud-1.svg";
import hourlyNightCloud2 from "./hourlyIcons/night/cloud-2.svg";
import hourlyNightCloud3 from "./hourlyIcons/night/cloud-3.svg";
import hourlyNightCloud4 from "./hourlyIcons/night/cloud-4.svg";
import hourlyNightCloud5 from "./hourlyIcons/night/cloud-5.svg";
import hourlyNightCloud6 from "./hourlyIcons/night/cloud-6.svg";
import hourlyNightCloud7 from "./hourlyIcons/night/cloud-7.svg";
import hourlyNightCloud8 from "./hourlyIcons/night/cloud-8.svg";
import hourlyNightCloud9 from "./hourlyIcons/night/cloud-9.svg";
import hourlyNightCloud10 from "./hourlyIcons/night/cloud-10.svg";
import hourlyNightCloud11 from "./hourlyIcons/night/cloud-11.svg";
import hourlyNightCloud12 from "./hourlyIcons/night/cloud-12.svg";
import hourlyNightCloud13 from "./hourlyIcons/night/cloud-13.svg";
import hourlyNightCloud14 from "./hourlyIcons/night/cloud-14.svg";
import hourlyNightCloud15 from "./hourlyIcons/night/cloud-15.svg";
import hourlyNightCloud16 from "./hourlyIcons/night/cloud-16.svg";
import hourlyNightCloud17 from "./hourlyIcons/night/cloud-17.svg";
import hourlyNightCloud18 from "./hourlyIcons/night/cloud-18.svg";
import hourlyNightCloud19 from "./hourlyIcons/night/cloud-19.svg";
import hourlyNightCloud20 from "./hourlyIcons/night/cloud-20.svg";
import hourlyNightCloud21 from "./hourlyIcons/night/cloud-21.svg";
import hourlyNightCloud22 from "./hourlyIcons/night/cloud-22.svg";
import hourlyNightCloud23 from "./hourlyIcons/night/cloud-23.svg";
import hourlyNightCloud24 from "./hourlyIcons/night/cloud-24.svg";
import hourlyNightCloud25 from "./hourlyIcons/night/cloud-25.svg";
import hourlyNightCloud26 from "./hourlyIcons/night/cloud-26.svg";
import hourlyNightCloud27 from "./hourlyIcons/night/cloud-27.svg";
import hourlyNightCloud28 from "./hourlyIcons/night/cloud-28.svg";
import hourlyNightCloud29 from "./hourlyIcons/night/cloud-29.svg";
import hourlyNightCloud30 from "./hourlyIcons/night/cloud-30.svg";
import hourlyNightCloud31 from "./hourlyIcons/night/cloud-31.svg";
import hourlyNightCloud32 from "./hourlyIcons/night/cloud-32.svg";
import hourlyNightCloud33 from "./hourlyIcons/night/cloud-33.svg";
import hourlyNightCloud34 from "./hourlyIcons/night/cloud-34.svg";
import hourlyNightCloud35 from "./hourlyIcons/night/cloud-35.svg";

export default {
  cloud1,
  cloud2,
  cloud3,
  cloud4,
  cloud5,
  cloud6,
  cloud7,
  cloud8,
  cloud9,
  cloud10,
  cloud11,
  cloud12,
  cloud13,
  cloud14,
  cloud15,
  cloud16,
  cloud17,
  cloud18,
  cloud19,
  hourlyCloud1,
  hourlyCloud2,
  hourlyCloud3,
  hourlyCloud4,
  hourlyCloud5,
  hourlyCloud6,
  hourlyCloud7,
  hourlyCloud8,
  hourlyCloud9,
  hourlyCloud10,
  hourlyCloud11,
  hourlyCloud12,
  hourlyCloud13,
  hourlyCloud14,
  hourlyCloud15,
  hourlyCloud16,
  hourlyCloud17,
  hourlyCloud18,
  hourlyCloud19,
  hourlyCloud20,
  hourlyCloud21,
  hourlyCloud22,
  hourlyCloud23,
  hourlyCloud24,
  hourlyCloud25,
  hourlyCloud26,
  hourlyCloud27,
  hourlyCloud28,
  hourlyCloud29,
  hourlyCloud30,
  hourlyCloud31,
  hourlyCloud32,
  hourlyCloud33,
  hourlyCloud34,
  hourlyCloud35,
  hourlyNightCloud1,
  hourlyNightCloud2,
  hourlyNightCloud3,
  hourlyNightCloud4,
  hourlyNightCloud5,
  hourlyNightCloud6,
  hourlyNightCloud7,
  hourlyNightCloud8,
  hourlyNightCloud9,
  hourlyNightCloud10,
  hourlyNightCloud11,
  hourlyNightCloud12,
  hourlyNightCloud13,
  hourlyNightCloud14,
  hourlyNightCloud15,
  hourlyNightCloud16,
  hourlyNightCloud17,
  hourlyNightCloud18,
  hourlyNightCloud19,
  hourlyNightCloud20,
  hourlyNightCloud21,
  hourlyNightCloud22,
  hourlyNightCloud23,
  hourlyNightCloud24,
  hourlyNightCloud25,
  hourlyNightCloud26,
  hourlyNightCloud27,
  hourlyNightCloud28,
  hourlyNightCloud29,
  hourlyNightCloud30,
  hourlyNightCloud31,
  hourlyNightCloud32,
  hourlyNightCloud33,
  hourlyNightCloud34,
  hourlyNightCloud35,
};
