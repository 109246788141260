import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConstantKey from "../../core/locale/strings.json";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import synDigitalLogo from "../../assets/images/synDigitalLogo.svg";
import "./errorHandler.less";

export const CommingSoon = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  return (
    <div className="landing-screen-container">
      <div className="landing-screen-content">
        <img
          className="syn-field-logo"
          src={synMyFieldLogo}
          alt="Syngenta Myfield Logo"
        />
        <div className="login-logout-container">
          <div className="myfield-welcome-message">
            {translate(ConstantKey.COMING_SOON)}
          </div>
          <button
            className="error-handler-login-btn"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            {translate(ConstantKey.RETURN_TO_DASHBOARD)}
          </button>
        </div>
      </div>
      <div className="syn-field-support">
        {translate(ConstantKey.NEED_HELP)}{" "}
        <a
          className="error-handler-signup-link"
          href="mailto:support@cropwise.com"
        >
          {translate(ConstantKey.SUPPORT_EMAIL)}
        </a>
        <br />
        <img
          className="syn-support-logo"
          src={synDigitalLogo}
          alt="Syngenta Digital Logo"
        />
      </div>
    </div>
  );
};

export default CommingSoon;
