import React, { useId, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  AntdConfigProvider,
} from "syngenta-digital-cropwise-react-ui-kit";
import type { ApplicationState } from "../../core/redux/reducers";
import { setSyngentaProductsDetails } from "../../core/redux/reducers/homePageSlice";
import ConstantKey from "../../core/locale/strings.json";
import cropwiseproduct from "../../assets/icons/cropwiseproduct.svg";
import plantPresription from "../../assets/icons/plantPrescription.svg";
import summary from "../../assets/icons/summary.svg";
import report from "../../assets/icons/report.svg";
import myfieldlogo from "../../assets/icons/myfield-icon.svg";
import cropwiselogo from "../../assets/icons/CropwiseLogo.svg";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { onSyngentaTechnicalReports } from "../../core/utils/analytics/constants";
import "./moresyngentatiles.less";

interface TileInfo {
  titleIcon: string;
  productIcon: string;
  description: string;
  urlPL: string;
  urlHU: string;
}
const MoreSyngentaTiles = (): JSX.Element => {
  const uid = useId();
  const history = useNavigate();
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();
  const SyngentaProducts = useSelector(
    (state: ApplicationState) => state.homepage
  );
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  useEffect(() => {
    // mock data for cropwise products
    const syngentatitlemockdata = [
      {
        titleIcon: "corpwiselogo",
        productIcon: "cropwiseproduct",
        description: ConstantKey.SEED_VARIETY_RECOMMENDATIONS,
        urlPL: "/vev-page/T1",
        urlHU: "/vev-page/E1",
      },
      {
        titleIcon: "corpwiselogo",
        productIcon: "plantPresription",
        description: ConstantKey.VARIABLE_RATEPLANT_PRESCRIPTION,
        urlPL: "https://www.interrascan.pl/",
        urlHU: "https://www.cropwise.com/hungary/planting",
      },
      {
        titleIcon: "corpwiselogo",
        productIcon: "summary",
        description: ConstantKey.MYFIELD_SUMMARY_INSIGHTS_REPORT,
        urlPL: "/mapview",
        urlHU: "/mapview",
      },
      {
        titleIcon: "corpwiselogo",
        productIcon: "report",
        description: ConstantKey.FIELD_MONITORING_REPORTS,
        urlPL: "/vev-page/K1",
        urlHU: "https://www.syngenta.hu/cropwise-protector",
      },
    ];
    dispatch(setSyngentaProductsDetails(syngentatitlemockdata));
  }, []);

  const getProductLogo = (log: string): any => {
    if (log === "myfield") return myfieldlogo;
    if (log === "corpwiselogo") return cropwiselogo;
  };
  const fetchSvgIcons = (iconName: string): any => {
    if (iconName === "report") return report;
    else if (iconName === "summary") return summary;
    else if (iconName === "plantPresription") return plantPresription;
    else if (iconName === "cropwiseproduct") return cropwiseproduct;
  };
  const logTilesClickAmplitudeEvent = (stateItem: PropertyKey): void => {
    if (stateItem === "/summaryinsights") {
      sendAmplitudeData(onSyngentaTechnicalReports);
    }
  };

  const onClickTiles = (tileInfo: TileInfo): void => {
    if (tileInfo?.urlPL === "/mapview") {
      history("/mapview", {
        state: { anchorId: "/mapview#insightsfromyourfarm" },
      });
    } else if (ProfileDataSelector?.defaultLocation?.countryCode === "pl") {
      logTilesClickAmplitudeEvent(tileInfo.urlPL);
      if (tileInfo.urlPL.includes("https"))
        window.open(tileInfo.urlPL, "_block");
      else history(tileInfo.urlPL);
    } else if (ProfileDataSelector?.defaultLocation?.countryCode === "hu") {
      logTilesClickAmplitudeEvent(tileInfo.urlHU);
      if (tileInfo.urlHU.includes("https"))
        window.open(tileInfo.urlHU, "_block");
      else history(tileInfo.urlHU);
    }
  };
  return (
    <AntdConfigProvider>
      <div className="grid-story-container">
        <div className="banner-title">
          {translate(ConstantKey.MORE_FROM_SYNGENTA)}
        </div>
        <Row align="top" justify="start">
          {SyngentaProducts?.syngentaProductsDetails?.map(
            (ele: any, i: number) => {
              return (
                <Col
                  flex={1}
                  offset={"0"}
                  order={i}
                  pull="0"
                  push="0"
                  key={uid}
                  onClick={() => {
                    onClickTiles(ele);
                  }}
                >
                  <div className="moresyngentalayout">
                    <div>
                      <span className="moreproduct_titletext">
                        {translate(ConstantKey.POWERED_BY)}
                      </span>
                      <span>
                        <img
                          src={getProductLogo(ele.titleIcon)}
                          className="moreproduct_titleicon"
                          alt="title icons"
                        />
                      </span>
                    </div>
                    <br />
                    <span>
                      <img
                        src={fetchSvgIcons(ele.productIcon)}
                        alt="cropwise product"
                      />
                    </span>
                    <br />

                    <span className="moreproductdescription">
                      {translate(ele.description)}
                    </span>
                  </div>
                </Col>
              );
            }
          )}
        </Row>
      </div>
    </AntdConfigProvider>
  );
};
export default MoreSyngentaTiles;
