import React from "react";

export const EditProperty = (): JSX.Element => {
  const organizationId = "62d4f9e1-172f-47e8-b7f3-7719fcfb9d5c";
  const propertyId = "aa3d650b-c312-40e6-972d-d1453701df6a";
  const createOrgDeepLink = `${
    process.env.REACT_APP_CROPWISE_APP_URL ?? ""
  }/deep-actions/edit-property?org_id=${organizationId}&property_id=${propertyId}&callback_uri=${
    process.env.REACT_APP_URL
  }/deep-action&state={state}`;

  React.useEffect(() => {
    window.location.href = createOrgDeepLink;
  }, [createOrgDeepLink]);

  return <>Redirecting to edit property/farm ....</>;
};

export default EditProperty;
