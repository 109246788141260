import east from "./direction_east.svg";
import north from "./direction_north.svg";
import northeast from "./direction_northeast.svg";
import northwest from "./direction_northwest.svg";
import south from "./direction_south.svg";
import southwest from "./direction_southwest.svg";
import southeast from "./direction_southeast.svg";
import west from "./direction_west.svg";

export default {
  east,
  north,
  northeast,
  northwest,
  south,
  southeast,
  southwest,
  west,
};
