import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Carousel } from "syngenta-digital-cropwise-react-ui-kit";
import moment from "moment";

import ForeCastApi from "../../core/api/forecastApi";
import type { ApplicationState } from "../../core/redux/reducers";
import {
  getDirectionIcons,
  getHourlyForeCastIcon,
} from "../../core/utils/forecastUtils";

import type { AllHoursForecastResponseDTO } from "../../core/types/mapView.dto";

import leftCarouselBtn from "../../assets/icons/left-carousel-btn.svg";
import rightCarouselBtn from "../../assets/icons/right-carousel-btn.svg";
import rainfallIcon from "../../assets/icons/rainfall.svg";
import thermostatIcon from "../../assets/icons/thermostat_icon.svg";

export const HourlyCarousel = (): JSX.Element => {
  const [allHoursForecastData, setAllHoursForecastData] = useState<
    AllHoursForecastResponseDTO[]
  >([]);
  const carouselRef: any = useRef(null);
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  useEffect(() => {
    if (
      profileDataSelector?.profileData?.countryCode &&
      profileDataSelector?.defaultPropertyCoordinates?.lat &&
      profileDataSelector?.defaultPropertyCoordinates?.lng
    ) {
      ForeCastApi.getTodayAllHoursForecastData(
        profileDataSelector?.defaultPropertyCoordinates?.lat,
        profileDataSelector?.defaultPropertyCoordinates?.lng,
        profileDataSelector.profileData?.countryCode
      )
        .then((response: any) => {
          if (response?.data?.length) {
            const allHoursForecastResponse: AllHoursForecastResponseDTO[] =
              response.data;
            if (allHoursForecastResponse.length) {
              setAllHoursForecastData([...allHoursForecastResponse]);
            }
          }
        })
        .catch(() => {});
    }
  }, []);

  return (
    <div className="all-hours-forecast">
      <div className="forecast-left-btn-sec">
        <button
          data-testid="prev-btn"
          className="forecast-carousel-btn mf-cursor-pointer"
          onClick={() => {
            carouselRef.current.prev();
          }}
        >
          <img
            src={leftCarouselBtn}
            className="forecast-carousel-left-icon"
            alt="leftCarouselBtn"
          />
        </button>
      </div>
      <div className="forecast-carousel-sec">
        <Carousel
          ref={carouselRef}
          dots={false}
          easing="linear"
          effect="scrollx"
          slidesToShow={8}
          slidesToScroll={3}
          responsive={[
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 440,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}
        >
          {allHoursForecastData.map(
            (eachHourData: AllHoursForecastResponseDTO) => {
              return (
                <div key={eachHourData.DATE_TIMESTAMP}>
                  {eachHourData?.DATE_TIMESTAMP && (
                    <div
                      key={eachHourData.DATE_TIMESTAMP}
                      className="each-hour-forecast-sec"
                    >
                      <div className="each-hour-time-label mf-pb-5">
                        {moment(eachHourData.DATE_TIMESTAMP).format("HH:mm")}
                      </div>
                      <div className="mf-pb-10 mf-pt-5">
                        <img
                          src={getHourlyForeCastIcon(
                            moment(eachHourData.DATE_TIMESTAMP).format("HH:mm"),
                            eachHourData.PictoCode_Hourly
                          )}
                          className="each-hour-forecast-pictoCode-icon"
                          alt="weatherIcon"
                        />
                      </div>
                      <div className="each-hour-temp-rain mf-pb-5">
                        <div className="each-hour-temp mf-mr-5">
                          <img
                            src={thermostatIcon}
                            className=""
                            alt="thermostatIcon"
                          />
                          <p
                            data-testid={`temperature-${eachHourData.DATE_TIMESTAMP}`}
                          >
                            {eachHourData.TempAir_Hourly_C}&deg;
                          </p>
                        </div>
                        <div className="each-hour-rain">
                          <img
                            src={rainfallIcon}
                            className=""
                            alt="rainfallIcon"
                          />
                          <p
                            data-testid={`precip-${eachHourData.DATE_TIMESTAMP}`}
                          >
                            {eachHourData.Precip_HourlySum_mm} mm
                          </p>
                        </div>
                      </div>
                      <div className="each-hour-wind">
                        <img
                          src={getDirectionIcons(
                            eachHourData.WindDirection_Hourly
                          )}
                          className=""
                          alt="windArrowIcon"
                        />
                        <p data-testid={`wind-${eachHourData.DATE_TIMESTAMP}`}>
                          {eachHourData.WindSpeed_Hourly_kmh} km/h
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
        </Carousel>
      </div>
      <div className="forecast-right-btn-sec">
        <button
          data-testid="next-btn"
          className="forecast-carousel-btn mf-cursor-pointer"
          onClick={() => {
            carouselRef.current.next();
          }}
        >
          <img
            src={rightCarouselBtn}
            className="forecast-carousel-right-icon"
            alt="rightCarouselBtn"
          />
        </button>
      </div>
    </div>
  );
};

export default HourlyCarousel;
