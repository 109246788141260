import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";
import ConstantKey from "../../core/locale/strings.json";
import { TECHINCAL_AGRONOMIC_REPORTS } from "../../core/constants/url";
import "./summaryinsights.less";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { technicalReportView } from "../../core/utils/analytics/constants";

const SummaryInsights = (): JSX.Element => {
  const { t: translate } = useTranslation();

  const onClickViewReport = (): void => {
    sendAmplitudeData(technicalReportView);
    window.open(TECHINCAL_AGRONOMIC_REPORTS, "_blank");
  };
  return (
    <div className="summaryinsightsroot">
      <div className="summaryinsightstitle">
        {translate(ConstantKey.SUMMARY_REPORT_INSIGHTS)}
      </div>
      <div className="summarytechnicaltitle">
        {translate(ConstantKey.SYNGENTA_TECHNICAL_REPORTS)}
      </div>
      <div className="summarytechincalreport">
        <Button
          type={ButtonType.primary}
          className="summarytechincalbutton"
          onClick={onClickViewReport}
        >
          {translate(ConstantKey.VIEW_REPORT)}
        </Button>
      </div>
    </div>
  );
};
export default SummaryInsights;
