import { useTranslation } from "react-i18next";
import ConstantKey from "../../core/locale/strings.json";
import CommingSoon from "../error-handler/comingsoon";
import "./claimedredeemedoffers.less";

const ClaimedRedeemedOffers = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div className="claimedredeemedoffers_body">
      {" "}
      <div className="myrecommendations_maintitle">
        {translate(ConstantKey.CLAIMED_AND_REDEEMED)}
      </div>
      <div className="myrecommendations_subtitle">
        {translate(ConstantKey.CLAMIMED_AND_REDEEMED_DECLARATION)}
      </div>
      <CommingSoon />
    </div>
  );
};

export default ClaimedRedeemedOffers;
