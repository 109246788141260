import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ButtonType,
  Col,
  notification,
  Row,
  Button,
  DatePicker,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";
import moment from "moment";

import ConstantKey from "../../core/locale/strings.json";
import type { ApplicationState } from "../../core/redux/reducers";
import PropertiesApi from "../../core/api/propertyAPI";
import type { FieldDetailListDto } from "../../core/types/fieldDetails.dto";
import { setFieldList } from "../../core/redux/reducers/fieldDetailsSlice";
import { setAllCrops } from "../../core/redux/reducers/mapviewSlice";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { onManageFieldClick } from "../../core/utils/analytics/constants";

import openInNewWindow from "../../assets/icons/openInNewWindow.svg";

import "./../mapView/mapView.less";
import "./manageFields.less";

export const ManageFields = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const profileInfoData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  const [fieldDetailListLoading, setFieldDetailListLoading] =
    useState<boolean>(false);
  const [fieldDetailList, setFieldDetailList] = useState<FieldDetailListDto[]>(
    []
  );

  useEffect(() => {
    if (profileInfoData?.defaultPropertyId) {
      getFieldsByProperty(profileInfoData?.defaultPropertyId);
    }
  }, [profileInfoData?.defaultPropertyId]);

  useEffect(() => {
    if (profileInfoData?.defaultLocation?.countryCode) {
      PropertiesApi.getAllCrops(profileInfoData?.defaultLocation?.countryCode)
        .then((res: any) => {
          dispatch(setAllCrops(res?.data));
        })
        .catch(() => {
          notification.error({
            message: `${translate(ConstantKey.FAILED_TO_FETCH_CROPS)}!`,
            description: "",
            placement: "topRight",
          });
        });
    }
  }, [profileInfoData?.defaultLocation?.countryCode]);

  const getFieldsByProperty = useCallback((propertyId: string) => {
    setFieldDetailListLoading(true);
    PropertiesApi.getFieldByPropertyId(propertyId)
      .then((response) => {
        if (response?.data?.content?.length) {
          const fieldIdsArray = response?.data?.content.map(
            (eachField: any) => eachField.id
          );
          getFieldDetailList(fieldIdsArray);
          const fieldDataArray = response?.data?.content.map(
            (eachField: any) => {
              return {
                id: eachField.id,
                name: eachField.name,
                coordinates: eachField.reference_point.coordinates,
              };
            }
          );
          if (fieldDataArray?.length) {
            setFieldList(fieldDataArray);
          }
        } else {
          setFieldDetailListLoading(false);
        }
      })
      .catch(() => {
        notification.error({
          message: `${translate(ConstantKey.FAILED_TO_FETCH_FIELDS)}!`,
          description: "",
          placement: "topRight",
        });
        setFieldDetailListLoading(false);
      });
  }, []);

  const getFieldDetailList = useCallback((fieldIds: string[]) => {
    PropertiesApi.fetchFieldData(fieldIds)
      .then((response) => {
        if (response?.data?.content?.length) {
          setFieldDetailList(response?.data?.content);
        }
        setFieldDetailListLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setFieldDetailListLoading(false);
      });
  }, []);

  const redirectToCropwiseFarmSettings = useCallback(() => {
    sendAmplitudeData(onManageFieldClick);
    navigate("/edit-field");
  }, []);

  const redirectToMapViewPage = useCallback(() => {
    navigate("/mapview");
  }, []);

  return (
    <div className="mf-grid-story-container mf-mb-10 map-view-page">
      {fieldDetailListLoading && <div className="mf-loader"></div>}
      <div className="mapview-header mf-mb-15">
        <div className="mapview-header-left">
          <h4
            className="mf-page-title mf-mb-15"
            data-testid="meteoblue-mapview-title"
          >
            {translate(ConstantKey.MANAGE_FIELDS_TITLE)}
          </h4>
        </div>
        <div className="mapview-header-right">
          <div className="map-layer-farm-btn-sec">
            <Button
              data-testid="create-new-field-btn"
              className="map-field-btn mf-mr-10"
              type={ButtonType.outline}
              onClick={redirectToMapViewPage}
            >
              {translate(ConstantKey.VIEW_THE_MAP)}
            </Button>
            <Button
              data-testid="manage-field-btn"
              type={ButtonType.outline}
              className="map-field-btn-outline"
              onClick={redirectToCropwiseFarmSettings}
            >
              <span className="mf-mr-10">
                {translate(ConstantKey.CROPWISE_FARM_SETTINGS)}
              </span>
              <img src={openInNewWindow} className="" alt="openInNewWindow" />
            </Button>
          </div>
        </div>
      </div>
      <div className="manage-fields-container">
        <h5
          className="managefield-sub-title"
          data-testid="meteoblue-mapview-title"
        >
          {translate(ConstantKey.MANAGE_FIELDS_SUB_TITLE)}
        </h5>
        <p className="managefield-title-desc">
          {translate(ConstantKey.MANAGE_FIELDS_TITLE_DESC)}
        </p>
        <div className="field-list-containers">
          {fieldDetailList.map((eachFieldDetail) => (
            <div
              key={eachFieldDetail.id}
              className="field-detail-container mf-mb-15 mf-p-15"
            >
              <Row>
                <Col xs={24} sm={24} md={8}>
                  <h6 className="manage-field-name">{eachFieldDetail.name}</h6>
                  <p>{eachFieldDetail.cropRefId}</p>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <p>Click on the fields below to edit:</p>
                  <p
                    className="select-label mf-pt-10"
                    style={{ fontSize: "12px" }}
                  >
                    {translate(ConstantKey.PLANTING_DATE)}
                  </p>
                  <DatePicker
                    data-testid="planting-date-input"
                    style={{ width: "50%" }}
                    format={"DD/MM/YYYY"}
                    value={moment(eachFieldDetail.event_date)}
                    disabled={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Button type={ButtonType.danger}>Delete Field</Button>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageFields;
