import { createSlice } from "@reduxjs/toolkit";
export interface PrecommitmentState {
  precommitmentDetails: any | null;
}
const initialState: PrecommitmentState = {
  precommitmentDetails: null,
};

export const precommitmentsSlice = createSlice({
  name: "precommitements",
  initialState,
  reducers: {
    setPrecommitmenDetails: (state, action) => {
      return {
        ...state,
        precommitmentDetails: action.payload,
      };
    },
  },
});

export const { setPrecommitmenDetails } = precommitmentsSlice.actions;
export default precommitmentsSlice.reducer;
