import { useTranslation } from "react-i18next";
import { Drawer } from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../../core/locale/strings.json";
import fieldsIcon from "../../../assets/icons/fields-icon.svg";
import cropCycleIcon from "../../../assets/icons/crop-cycle-icon.svg";
import productIcon from "../../../assets/icons/product-icon.svg";
import equipmentIcon from "../../../assets/icons/equipment-icon.svg";
import addUserIcon from "../../../assets/icons/add-user-Icon.svg";
import openLinkNewTabIcon from "../../../assets/icons/openLinkNewTabIcon.svg";
import "./globalHeader.less";

interface SettingsDrawerDTO {
  setVisible: (value: boolean) => void;
  visible: boolean;
}

export const SettingsDrawer = ({
  setVisible,
  visible,
}: SettingsDrawerDTO): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Drawer
      title={
        <h1 className="mf-drawer-title">
          {translate(ConstantKey.EDIT_AND_MANAGER)}
        </h1>
      }
      placement="right"
      closable={true}
      onClose={() => {
        setVisible(false);
      }}
      open={visible}
      footer={<></>}
      className="mf-drawer-content"
      width={296}
    >
      <div className="navbar-settings">
        <div className="group">
          <div className="navbar-setting-group">
            <button type="button" className="navbar-farm-setting">
              <h2 className="farm-settings-title">
                {translate(ConstantKey.CROPWISE_FARM_SETTINGS)}
              </h2>
              <img
                className="mf-pl-10"
                src={openLinkNewTabIcon}
                alt="openLinkNewTabIcon"
              />
            </button>
            <div className="navbar-settings-btn-list">
              <button type="button" className="navbar-setting-btn">
                <div className="setting-btn-icon">
                  <img className="mf-p-4" src={fieldsIcon} alt="fieldsIcon" />
                </div>
                <p className="navbar-setting-title">
                  {translate(ConstantKey.FIELDS)}
                </p>
                <div className="setting-open-icon">
                  <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
                </div>
              </button>
              <button type="button" className="navbar-setting-btn">
                <div className="setting-btn-icon">
                  <img
                    className="mf-p-4"
                    src={cropCycleIcon}
                    alt="cropCycleIcon"
                  />
                </div>
                <p className="navbar-setting-title">
                  {translate(ConstantKey.CROP_CYCLES)}
                </p>
                <div className="setting-open-icon">
                  <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
                </div>
              </button>
              <button type="button" className="navbar-setting-btn">
                <div className="setting-btn-icon">
                  <img className="mf-p-4" src={productIcon} alt="productIcon" />
                </div>
                <p className="navbar-setting-title">
                  {translate(ConstantKey.PRODUCTS)}
                </p>
                <div className="setting-open-icon">
                  <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
                </div>
              </button>
              <button type="button" className="navbar-setting-btn">
                <div className="setting-btn-icon">
                  <img
                    className="mf-p-4"
                    src={equipmentIcon}
                    alt="equipmentIcon"
                  />
                </div>
                <p className="navbar-setting-title">
                  {translate(ConstantKey.EQUIPMENTS)}
                </p>
                <div className="setting-open-icon">
                  <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-settings mf-pt-10">
        <div className="group">
          <div className="navbar-setting-group">
            <button type="button" className="navbar-farm-setting">
              <h2 className="farm-settings-title">
                {translate(ConstantKey.ACCOUNTS)}
              </h2>
              <img
                className="mf-pl-10"
                src={openLinkNewTabIcon}
                alt="openLinkNewTabIcon"
              />
            </button>
            <div className="navbar-settings-btn-list">
              <button type="button" className="navbar-setting-btn">
                <div className="setting-btn-icon">
                  <img className="mf-p-4" src={addUserIcon} alt="addUserIcon" />
                </div>
                <p className="navbar-setting-title">
                  {translate(ConstantKey.ACCESS_YOUR_CROPWISE_ACCOUNT)}
                </p>
                <div className="setting-open-icon">
                  <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default SettingsDrawer;
