import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.json";
import pl from "./translations/pl.json";
import hu from "./translations/hu.json";
import ro from "./translations/ro.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    resources: {
      en: { translation: en },
      pl: { translation: pl },
      hu: { translation: hu },
      ro: { translation: ro },
    },
    fallbackLng: "en",
  })
  .catch((_error) => {});

export default i18n;
