import { useCallback, useEffect, useReducer, useState } from "react";
import {
  Button,
  ButtonType,
  Col,
  Modal,
  notification,
  Row,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

import ConstantKey from "../../core/locale/strings.json";
import {
  SignInReduceActionTypeDTO,
  type SignInReducerActionDTO,
  type FormState,
} from "../../core/types/authentication.dto";
import GrowerApi from "../../core/api/growerAPI";
import useForm, { isValidEmail } from "../../core/hook/useForm";

import CustomMenu from "../../shared/components/menu";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";
import AuthFarmImage from "../../shared/components/AuthFarmImage";

import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import infoWarningIcon from "../../assets/icons/info-warning-icon.svg";

import mailLogo from "../../assets/icons/mailLogo.svg";

import "./signin.less";

export function loginReducer(
  state: FormState,
  action: SignInReducerActionDTO
): any {
  switch (action.type) {
    case "EMAIL":
      return { ...state, email: { ...state.email, value: action.payload } };
    case "PASSWORD":
      return {
        ...state,
        password: { ...state.password, value: action.payload },
      };
    default:
      throw new Error();
  }
}
export const initialFormState: FormState = {
  email: {
    value: "",
    required: true,
    validate: isValidEmail,
    validateMessage: ConstantKey.INVALID_EMAIL,
  },
  password: {
    value: "",
    required: true,
  },
};

export const SignInComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const location = useLocation();

  const [state, dispatch] = useReducer(loginReducer, initialFormState);

  const [step, setStep] = useState<"step1" | "step2">("step1");
  const [isUserNotAllowed, setIsUserNotAllowed] = useState<boolean>(false);
  const [isHaveContracts, setIsHaveContracts] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { formErrors, handleChange } = useForm(initialFormState);

  const modalErroInfo = {
    closable: false,
    title: translate(ConstantKey.MYFIELD_CONTRACT_ERROR),
    open: isHaveContracts,
    content: (
      <a className="splash-signup-link" href="mailto:support@cropwise.com">
        <span className="mf-ml-5"> {translate(ConstantKey.SUPPORT_EMAIL)}</span>
      </a>
    ),
    onOk: () => {
      setIsHaveContracts(false);
      navigate("/splash");

      window.location.reload();
    },
  };

  useEffect(() => {
    if (location?.state?.email) {
      dispatch({
        type: SignInReduceActionTypeDTO.EMAIL,
        payload: location.state.email,
      });
    }
  }, [location.state]);

  const continueNextStop = useCallback(() => {
    const domain = state?.email?.value.split("@")[1];
    if (domain === "syngenta.com") {
      setIsUserNotAllowed(true);
      return;
    }
    setLoading(true);
    GrowerApi.checkGrowerInfo(state?.email?.value)
      .then((res) => {
        if (res.status === 204) {
          setIsOpen(true);
        } else {
          setStep("step2");
        }
      })
      .catch((error) => {
        notification.error({
          message: `${translate(ConstantKey.SOMETHING_WENT_WRONG)}!`,
          description: "",
          placement: "topRight",
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [state]);

  const loginUser = useCallback(() => {
    setLoading(true);
    GrowerApi.growerSignIn({
      email: state.email.value,
      password: state.password.value,
      grant_type: "password",
    })
      .then((res) => {
        if (res?.data?.licensing_details?.apps?.length !== 0) {
          if (res) {
            localStorage.setItem(
              "oauth_api_response",
              JSON.stringify(res.data)
            );
            localStorage.setItem("access_tokens", res.data.access_token);
            localStorage.setItem("refresh_tokens", res.data.refresh_token);
            window.location.reload();
          }
          setStep("step2");
        } else {
          setIsHaveContracts(true);
          Modal.error(modalErroInfo);
        }
      })
      .catch((error) => {
        const message =
          error?.cause?.data?.errorMessage ??
          translate(ConstantKey.SOMETHING_WENT_WRONG);
        notification.error({
          message,
          description: "",
          placement: "topRight",
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [state]);

  const redirectToSignUpFlow = useCallback(() => {
    setIsOpen(false);
    navigate("/signup-basic-info", {
      state: { email: { value: state.email.value } },
    });
  }, [state]);

  const emailOnChange = useCallback((event: any) => {
    handleChange("email", event.target.value);
    dispatch({
      type: SignInReduceActionTypeDTO.EMAIL,
      payload: event.target.value,
    });
  }, []);

  const passwordOnChange = useCallback((event: any) => {
    handleChange("password", event.target.value);
    dispatch({
      type: SignInReduceActionTypeDTO.PASSWORD,
      payload: event.target.value,
    });
  }, []);

  return (
    <>
      <Modal
        className="user-not-found-modal"
        cancelText={translate(ConstantKey.CANCEL)}
        title={
          <div className="new-error-modal-container">
            <img
              className="anticon"
              src={infoWarningIcon}
              alt="Info Warning Icon"
            />
            <span className="error-modal-header-message">
              {translate(ConstantKey.ERROR)}
            </span>
          </div>
        }
        mask
        open={isOpen}
        okText={translate(ConstantKey.CREATE_NEW_ACCOUNT)}
        okType="primary"
        onCancel={() => {
          setIsOpen(false);
        }}
        onOk={redirectToSignUpFlow}
        closable={true}
      >
        <p>{translate(ConstantKey.NO_ACCOUNT_FOUND)}</p>
        {state?.email?.value && (
          <div className="error-modal-user-details">
            <img className="mf-mr-5" src={mailLogo} alt="Info Warning Icon" />
            <span>{state?.email?.value}</span>
          </div>
        )}
      </Modal>
      <Modal
        className="user-not-found-modal"
        title={
          <div className="new-error-modal-container">
            <img
              className="anticon"
              src={infoWarningIcon}
              alt="Info Warning Icon"
            />
            <span className="error-modal-header-message">
              {translate(ConstantKey.USER_NOT_ALLOWED)}
            </span>
          </div>
        }
        mask
        open={isUserNotAllowed}
        okText={translate(ConstantKey.OK)}
        okType="primary"
        onOk={() => {
          setIsUserNotAllowed(false);
        }}
        cancelText={translate(ConstantKey.CANCEL)}
        onCancel={() => {
          setIsUserNotAllowed(false);
        }}
        closable={true}
      >
        <p>
          {translate(ConstantKey.SYNGENTA_USER_NOT_ALLOWED)}
          <br />
          <b>
            {translate(ConstantKey.CONTACT_FOR_SUPPORT)}
            <a
              className="splash-signup-link"
              href="mailto:support@cropwise.com"
            >
              {translate(ConstantKey.SUPPORT_EMAIL)}
            </a>
          </b>
        </p>
        <br />
        {state?.email?.value && (
          <div className="error-modal-user-details">
            <img
              className="mf-mr-5"
              src={mailLogo}
              alt="SYNGENTA_USER_NOT_ALLOWED"
            />
            <span>{state?.email?.value}</span>
          </div>
        )}
      </Modal>

      <Row>
        <Col xs={24} sm={24} md={12} prefixCls="login-sec">
          <div className="login-container">
            <div className="flex-grow-0">
              <img
                className="syn-field-logo flex-grow-0"
                src={synMyFieldLogo}
                alt="Syngenta Myfield Logo"
              />
            </div>
            <div className="flex-grow-0">
              <p className="sign-in-desc ">
                {translate(ConstantKey.SIGN_IN_TITLE)}
              </p>
            </div>
            <div className="login-form flex-grow-0">
              <div className="mf-width-100 mf-pr-10 mf-pl-10">
                <p className="select-label mf-pt-10">
                  {translate(ConstantKey.YOUR_EMAIL)}
                </p>
                <Input
                  type="default"
                  placeholder={translate(ConstantKey.ENTER_YOUR_EMAIL)}
                  size="middle"
                  data-testid="sign-in-email"
                  className={`mf-accounts-input ${
                    formErrors?.email && "mf-error-field"
                  }`}
                  value={state.email.value}
                  onChange={emailOnChange}
                />
                {formErrors?.email && (
                  <p
                    className="mf-field-error-msg"
                    data-testid="login-form-error-email"
                  >
                    {formErrors?.email}
                  </p>
                )}
              </div>
              {step === "step2" && (
                <>
                  <div className="mf-width-100 mf-pr-10 mf-pl-10">
                    <p className="select-label mf-pt-10">
                      {translate(ConstantKey.PASSWORD)}
                    </p>
                    <Input.Password
                      type="default"
                      placeholder={translate(ConstantKey.ENTER_PASSWORD)}
                      size="middle"
                      className={`mf-accounts-input ${
                        formErrors?.password && "mf-error-field"
                      }`}
                      data-testid="sign-in-password"
                      value={state.password.value}
                      onChange={passwordOnChange}
                    />
                    {formErrors?.password && (
                      <p
                        className="mf-field-error-msg"
                        data-testid="login-form-error-password"
                      >
                        {formErrors?.password}
                      </p>
                    )}
                  </div>
                  <Button
                    loading={loading}
                    type={ButtonType.info}
                    data-testid="sign-in-btn"
                    className={`login-btn ${
                      !(state.email.value && state.password.value)
                        ? "login-btn-disabled"
                        : ""
                    }`}
                    key="loginBtn"
                    onClick={loginUser}
                    disabled={
                      !(
                        state.email.value &&
                        !formErrors?.email &&
                        state.password.value &&
                        !formErrors?.password
                      ) || loading
                    }
                  >
                    {translate(ConstantKey.SIGN_IN)}
                  </Button>
                </>
              )}
              {step === "step1" && (
                <Button
                  loading={loading}
                  type={ButtonType.info}
                  data-testid="sign-in-continue-btn"
                  className={`login-btn ${
                    !state.email.value ? "login-btn-disabled" : ""
                  }`}
                  key="loginBtn"
                  onClick={continueNextStop}
                  disabled={
                    loading || !(state.email.value && !formErrors?.email)
                  }
                >
                  {translate(ConstantKey.CONTINUE)}
                </Button>
              )}
            </div>
            <div className="lng-options flex-grow-1">
              <CustomMenu
                disabledOverflow
                isUsingDefaultOnSelect
                customLanguageLabel={RenderLanguageLabel}
                className="lng-selection-dropdown"
              />
            </div>
          </div>
        </Col>
        <Col xs={0} sm={0} md={12} prefixCls="farm-image">
          <AuthFarmImage />
        </Col>
      </Row>
    </>
  );
};

export default SignInComponent;
