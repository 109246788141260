import { createSlice } from "@reduxjs/toolkit";
import type { TemplateData } from "../../types/productTemplate.dto";
export interface ProductTemplateState {
  templateDetails: TemplateData | null;
  bannerDetails: {
    alertsRecommendation: [];
    newOffers: [];
    newProduct: [];
    claimedAndCommittedOffers: [];
  } | null;
  anchorId: string | null;
}
const initialState: ProductTemplateState = {
  templateDetails: null,
  bannerDetails: null,
  anchorId: null,
};

export const productTemplateSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setTemplateDetails: (state, action) => {
      return {
        ...state,
        templateDetails: { ...action.payload },
      };
    },
    setBannerDetails: (state, action) => {
      return {
        ...state,
        bannerDetails: { ...action.payload },
      };
    },
    setAnchorId: (state, action) => {
      return {
        ...state,
        anchorId: action.payload,
      };
    },
  },
});

export const { setTemplateDetails, setBannerDetails, setAnchorId } =
  productTemplateSlice.actions;
export default productTemplateSlice.reducer;
