import { useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import ConstantKey from "../../core/locale/strings.json";
import type { ApplicationState } from "../../core/redux/reducers";
import { setPrecommitmenDetails } from "../../core/redux/reducers/precommitmentsSlice";
import { isMobileView } from "../../core/utils/common-function";
import { PRE_COMMITMENTS_CREATION } from "../../core/constants/url";
import preCommitmentDateIcon from "../../assets/icons/pre_commitmet_date_icon.svg";
import voucherIcon from "../../assets/icons/voucherIcon.svg";
import arrowDropDown from "../../assets/icons/arrow_drop_down_24px.svg";
import "./precommitments.less";

interface PrecommitmentSubData {
  material_name: string;
  plan_created_date: string;
  item_planned_qty: number;
}
const Precommitment = (): JSX.Element => {
  const uid = useId();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [apiFailed, setApiFailed] = useState(null as null | boolean);
  const [loader, setLoader] = useState(false as boolean);
  const PrecommitmentState = useSelector(
    (state: ApplicationState) => state.PrecommitmentState
  );

  useEffect(() => {
    if (PrecommitmentState?.precommitmentDetails === null) {
      setLoader(true);
      ProductTemplateApi.getPreCommitments()
        .then((response) => {
          if (response?.status === 200) {
            const convertedDateData = convertDateData(
              response?.data?.planCreateDate
            );
            const convertedVoucherIdData = convertVoucherData(
              response?.data?.voucherId
            );
            dispatch(
              setPrecommitmenDetails({
                planCreateDate: convertedDateData,
                voucherId: convertedVoucherIdData,
              })
            );
            setApiFailed(false);
          }
        })
        .catch(() => {
          setApiFailed(true);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, []);

  const convertDateData = (apiResponse: any): any => {
    let displayData = {};
    const isMobile = isMobileView();
    Object.keys(apiResponse).forEach((responseData: any) => {
      displayData = {
        ...displayData,
        [responseData]: {
          info: apiResponse[responseData],
          selected: !isMobile,
        },
      };
    });
    return displayData;
  };
  const convertVoucherData = (apiResponse: any): any => {
    let displayData = {};
    const isMobile = isMobileView();
    Object.keys(apiResponse).forEach((responseData: any) => {
      displayData = {
        ...displayData,
        [responseData]: {
          info: apiResponse[responseData],
          selected: !isMobile,
          createdDate: apiResponse[responseData][0].plan_created_date,
        },
      };
    });
    return displayData;
  };
  const onClickCreateButton = (): void => {
    window.open(PRE_COMMITMENTS_CREATION, "_blank", "noopener");
  };
  const onClickSeeMoreDate = (item: string): void => {
    const precommitments =
      PrecommitmentState?.precommitmentDetails?.planCreateDate;
    const selectedItemChanges = {
      ...precommitments,
      [item]: {
        ...precommitments[item],
        selected: !precommitments[item].selected,
      },
    };
    dispatch(
      setPrecommitmenDetails({
        ...PrecommitmentState?.precommitmentDetails,
        planCreateDate: selectedItemChanges,
      })
    );
  };
  const onClickSeeMoreVoucher = (item: string): void => {
    const precommitments = PrecommitmentState?.precommitmentDetails?.voucherId;
    const selectedItemChanges = {
      ...precommitments,
      [item]: {
        ...precommitments[item],
        selected: !precommitments[item].selected,
      },
    };
    dispatch(
      setPrecommitmenDetails({
        ...PrecommitmentState?.precommitmentDetails,
        voucherId: selectedItemChanges,
      })
    );
  };
  const displaySubItems = (item: PrecommitmentSubData): any => {
    return (
      <div className="mf-mt-10" key={uid}>
        <div className="precommitment_bold">{item.material_name}</div>
        {item.plan_created_date}
        <div></div>
        <div className="precommitment_color_green">
          {item.item_planned_qty} {translate(ConstantKey.UNITS)}
        </div>
      </div>
    );
  };
  return (
    <div className=" precommitments_body">
      {loader && PrecommitmentState?.precommitmentDetails === null && (
        <div className="mf-loader"></div>
      )}
      <div className="precommit_title">
        <div>
          <div className="precommit_maintitle">
            {translate(ConstantKey.PRE_COMMITTED_OFFERS)}
          </div>
          <div className="precommit_subtitle">
            {translate(ConstantKey.PRE_COMMITMENTS_DECLARATION_PRODUCTS)}
          </div>
        </div>
        <Button
          type={ButtonType.primary}
          className="precommitments_create_button"
          onClick={onClickCreateButton}
        >
          {translate(ConstantKey.CREATE_PRE_COMMITMENTS)}
        </Button>
      </div>
      <div className="precommitment_margin_top">
        {PrecommitmentState?.precommitmentDetails?.voucherId &&
          Object.keys(PrecommitmentState?.precommitmentDetails?.voucherId)?.map(
            (voucher: string) => {
              return (
                <div className="precommitment_details_content " key={uid}>
                  <div className="precommitment_details_top_section">
                    <div className="precommitment_details_text">
                      <div className="precommitment_bold">
                        <span className="precommit_pre_icon">
                          <img
                            className="precommitment_icon"
                            src={voucherIcon}
                            alt="dateicon"
                          />
                          <span className="mf-ml-5 precommit_pre_commit">
                            {translate(ConstantKey.VOUCHER)}
                          </span>
                        </span>
                        <span className="precommitment_grid_spam">
                          <span className="">{voucher}</span>
                          <span className="precommit_vocher_date">
                            <span className=" precommitment_gray_color">
                              {translate(ConstantKey.CREATED)}
                            </span>
                            <span className="precommitment_color_green mf-ml-5">
                              {
                                PrecommitmentState?.precommitmentDetails
                                  ?.voucherId[voucher]?.createdDate
                              }
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div>
                      <Button
                        type={ButtonType.primary}
                        className="precommitment_view_document_button"
                      >
                        {translate(ConstantKey.VIEW_DOCUMENT)}
                      </Button>
                      <div className="precommitment_see_more">
                        <button
                          className="mf-cursor-pointer sidebar_button"
                          onClick={() => {
                            onClickSeeMoreVoucher(voucher);
                          }}
                        >
                          {translate(ConstantKey.SEE_MORE)}
                        </button>{" "}
                        <img src={arrowDropDown} alt="arowicon" />
                      </div>
                    </div>
                  </div>
                  {PrecommitmentState?.precommitmentDetails?.voucherId[voucher]
                    ?.selected && (
                    <div className="precommitment_details_subcontent">
                      {PrecommitmentState?.precommitmentDetails?.voucherId[
                        voucher
                      ]?.info.map((voucherData: PrecommitmentSubData) =>
                        displaySubItems(voucherData)
                      )}
                    </div>
                  )}
                </div>
              );
            }
          )}
        {PrecommitmentState?.precommitmentDetails?.planCreateDate &&
          Object.keys(
            PrecommitmentState?.precommitmentDetails?.planCreateDate
          )?.map((date: string) => {
            return (
              <div className="precommitment_details_content " key={uid}>
                <div className="precommitment_details_top_section">
                  <div className="precommitment_details_text">
                    <div className="precommitment_bold">
                      <span className="precommit_pre_icon">
                        <img
                          className="precommitment_icon"
                          src={preCommitmentDateIcon}
                          alt="dateicon"
                        />
                        <span className="mf-ml-5 precommit_pre_commit">
                          {translate(ConstantKey.PRE_COMMITED)}
                        </span>
                      </span>
                      <span className="precommitment_create_date">
                        <span className="mf-ml-10 precommitment_gray_color">
                          {translate(ConstantKey.CREATED)}
                        </span>
                        <span className="precommitment_color_green mf-ml-5">
                          {date}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <Button
                      type={ButtonType.primary}
                      className="precommitment_view_document_button"
                    >
                      {translate(ConstantKey.VIEW_DOCUMENT)}
                    </Button>
                    <div className="precommitment_see_more">
                      <button
                        className="mf-cursor-pointer sidebar_button"
                        onClick={() => {
                          onClickSeeMoreDate(date);
                        }}
                      >
                        {translate(ConstantKey.SEE_MORE)}
                      </button>{" "}
                      <img src={arrowDropDown} alt="arowicon" />
                    </div>
                  </div>
                </div>
                {PrecommitmentState?.precommitmentDetails?.planCreateDate[date]
                  ?.selected && (
                  <div className="precommitment_details_subcontent">
                    {PrecommitmentState?.precommitmentDetails?.planCreateDate[
                      date
                    ]?.info.map((dateData: PrecommitmentSubData) =>
                      displaySubItems(dateData)
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {apiFailed && (
        <div className="precommitment_error_msg_body recommendation_bold">
          {translate(ConstantKey.SERVICE_IS_UNAVAILABLE)}
        </div>
      )}
    </div>
  );
};
export default Precommitment;
