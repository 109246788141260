import { type FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import {
  Avatar,
  Col,
  Divider,
  Row,
  Space,
  TopNavBar,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useNavigate } from "react-router-dom";

import SettingsNavigation from "./settingsNavigation";
import MyFieldLogo from "./MyFieldLogo";
import CustomMenu from "../../components/menu";

import ConstantKey from "../../../core/locale/strings.json";
import {
  type GlobalHeaderDTO,
  type ProfileDTO,
} from "../../../core/types/headers.dto";
import { userLogout } from "../../../core/utils/analytics/constants";
import {
  clearUserProperties,
  sendAmplitudeData,
} from "../../../core/utils/analytics";
import { type ApplicationState } from "../../../core/redux/reducers";

import logoutIcon from "../../../assets/icons/logout.svg";
import openLinkNewTabIcon from "../../../assets/icons/openLinkNewTabIcon.svg";

import "./globalHeader.less";

const getInitials = (name: string): string => {
  try {
    const firstName = name.split(" ")[0];
    const lastName = name.length > 0 ? name.split(" ")[1] : " ";
    return `${firstName.split("")[0]}${lastName.split("")[0]}`.toUpperCase();
  } catch {
    return "NA";
  }
};

const logout = (): void => {
  const oauthURL = process.env.REACT_APP_CROPWISE_BASE_URL;
  if (oauthURL) {
    sendAmplitudeData(userLogout);
    clearUserProperties();
    localStorage.clear();
    window.location.href = `${oauthURL}/exit?continue=${process.env.REACT_APP_URL}`;
  }
};

export const ProfileOverlay = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const profileDataSelector = useSelector((state: any) => state.accountDetials);
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );

  const languageLabels: Record<string, string> = {
    en: ConstantKey.ENGLISH,
    pl: ConstantKey.POLSKI,
    hu: ConstantKey.HUNGARIAN,
    ro: ConstantKey.ROMANIAN,
  };

  const renderLanguageLabel = (): JSX.Element => {
    return (
      <div className="custom-language-menu-title">
        <p className="custom-language-label">
          {translate(ConstantKey.LANGUAGE)}
        </p>
        {languageLabels[homePageState?.currentLanguage as string]}
      </div>
    );
  };
  const onClickEditProfile = (): void => {
    const userProfileUrl = `${
      process.env.REACT_APP_CROPWISE_BASE_URL ?? ""
    }/app/profile`;
    window.location.href = userProfileUrl;
  };

  return (
    <div data-cy="profileOverlay" className="logoutOverlayBlock">
      <div className="profile-dropdown-info">
        <h3>{translate(ConstantKey.ACCOUNT)}</h3>
        <div>
          <Row>
            <Col span={4}>
              <Avatar shape="circle" size={40} style={{ fontSize: "12px" }}>
                {getInitials(profileDataSelector.profileData.name)}
              </Avatar>
            </Col>
            <Col span={20}>
              <h4>
                {profileDataSelector.profileData?.name
                  ? profileDataSelector.profileData.name
                  : "NA"}
              </h4>
            </Col>
          </Row>
        </div>
        <ul className="mf-elements-menu">
          <li>
            <Row className="edit-profile-link">
              <Col span={22} onClick={onClickEditProfile}>
                <h4>{translate(ConstantKey.EDIT_PROFILE)}</h4>
              </Col>
              <Col span={2}>
                <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
              </Col>
            </Row>
          </li>
          <li>
            <Divider className="profile-navigation-divider" />
          </li>
          <li>
            <Row className="profile-navigation-menu-title">
              <Col
                span={24}
                onClick={() => {
                  navigate("/preferences");
                }}
              >
                {translate(ConstantKey.PREFERENCES)}
              </Col>
            </Row>
            <Row>
              <CustomMenu
                customLanguageLabel={renderLanguageLabel}
                mode="vertical"
              />
            </Row>
          </li>
          <li>
            <Divider className="profile-navigation-divider" />
          </li>
          <li>
            <Row>
              <Col span={22}>
                <h4>{translate(ConstantKey.LEAGAL_DOCUMENT)}</h4>
              </Col>
              <Col span={2} className="mf-pt-5">
                <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
              </Col>
            </Row>
          </li>
        </ul>
      </div>
      <button
        className="profile-dropdown-logout mf-btn-img"
        data-testid="logout-btn"
        onClick={logout}
      >
        <Space align="start" direction="horizontal" wrap={true} size="small">
          <img src={logoutIcon} alt="logoutIcon" />
          <h4>{translate(ConstantKey.LOG_OUT_FROM_MYFIELD)}</h4>
        </Space>
      </button>
    </div>
  );
};

export const Profile: FC<ProfileDTO> = ({ initials }) => {
  return (
    <Dropdown
      trigger={["click"]}
      overlay={<ProfileOverlay />}
      placement="topRight"
    >
      <button
        className="mf-btn-img"
        data-testid="profileAvatarBtn"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Avatar shape="circle" size={40} style={{ fontSize: "14px" }}>
          {initials}
        </Avatar>
      </button>
    </Dropdown>
  );
};

export const GlobalHeader = ({
  unAuthHeader,
}: GlobalHeaderDTO): JSX.Element => {
  const profileDataSelector = useSelector((state: any) => state.accountDetials);
  return (
    <TopNavBar
      logo={<MyFieldLogo />}
      title={""}
      newDesign={true}
      userProfile={
        !unAuthHeader && (
          <Profile
            initials={getInitials(profileDataSelector.profileData.name)}
          />
        )
      }
      rightSection={!unAuthHeader && <SettingsNavigation />}
    />
  );
};
