import amplitude from "amplitude-js";

export const initAmplitude = (): void => {
  amplitude.getInstance().init(process.env.REACT_APP_API_KEY as string);
};

export const setUserProperties = (
  properties: Record<string, unknown>
): void => {
  amplitude.getInstance().setUserProperties(properties);
};

export const clearUserProperties = (): void => {
  amplitude.getInstance().clearUserProperties();
};

export const sendAmplitudeData = (
  eventType: string,
  eventProperties?: Record<string, unknown>
): void => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
