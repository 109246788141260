import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import type { ApplicationState } from "../../../core/redux/reducers";
import { setAnchorId } from "../../../core/redux/reducers/productTemplateSlice";
import { APP_APPLE_URI, APP_GOOGLE_URL } from "../../../core/constants/url";
import ConstantKey from "../../../core/locale/strings.json";
import notification from "../../../assets/icons/notifications.svg";
import locationIcon from "../../../assets/icons/edit_location.svg";
import dashboardIcon from "../../../assets/icons/dashboard_icon.svg";
import cloudyIcon from "../../../assets/icons/wb_cloudy.svg";
import mapviewIcon from "../../../assets/icons/mapview_icon.svg";
import myoffersIcon from "../../../assets/icons/myoffers_icon.svg";
import recommendationsIcon from "../../../assets/icons/recommendation_icon.svg";
import productsAndServicesIcon from "../../../assets/icons/productsandservices_icon.svg";
import contactsIcon from "../../../assets/icons/contacts_icon.svg";
import myfieldapp from "../../../assets/images/myfieldapp.svg";
import { osfunction } from "../../../core/utils/common-function";
import { sendAmplitudeData } from "../../../core/utils/analytics";
import {
  claimOffersClick,
  commitedOffersClick,
  mapTabClick,
  newOffersClick,
  recommendationClick,
  titleSyngnetaTechnicalReportsClick,
} from "../../../core/utils/analytics/constants";
import "./sidebar.less";

interface RouteDTO {
  name: string;
  path: string;
  select: boolean;
  icon?: any;
}

const SidebarMenu: React.FC = () => {
  const history = useNavigate();
  const locations = useLocation();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const [mainMenu, setMainMenu] = useState<any>({
    dashboard: {
      name: ConstantKey.DASHBOARD,
      path: "/dashboard",
      select: false,
      icon: dashboardIcon,
    },
    mapview: {
      name: ConstantKey.MAP_VIEW,
      path: "/mapview",
      select: false,
      icon: mapviewIcon,
    },
    mapviewIframse: {
      name: ConstantKey.METEOBLUE_MAP,
      path: "/metablue-map",
      select: false,
      icon: cloudyIcon,
    },
  });
  const [recommendations, setRecommendations] = useState<any>({
    seedrecommendations: {
      name: ConstantKey.CREATE_SEED_RECOMMENDATION,
      path: "/createseedrecommendation",
      select: false,
    },
    myrecommendations: {
      name: ConstantKey.MY_RECOMMEDATIONS,
      path: "/myrecommendations",
      select: false,
    },
    summaryinstights: {
      name: ConstantKey.SUMMARY_INSIGHTS,
      path: "/summaryinsights",
      select: false,
    },
  });

  const [offersMenu, setOffersMenu] = useState<any>({
    newoffers: {
      name: ConstantKey.NEW_OFFERS,
      path: "/newoffers",
      select: false,
    },
    committedoffers: {
      name: ConstantKey.COMMITED_OFFERS,
      path: "/pre-commitments",
      select: false,
    },
    claimed: {
      name: ConstantKey.CLAIMED,
      path: "/claimed-redeemed",
      select: false,
    },
  });
  useEffect(() => {
    selectMenuItemOnClick(window.location.pathname);
  }, [window.location.pathname]);

  const logSideBarMenuClickAmplitudeEvent: Record<string, () => void> = {
    newoffers: () => {
      sendAmplitudeData(newOffersClick);
    },
    committedoffers: () => {
      sendAmplitudeData(commitedOffersClick);
    },
    claimed: () => {
      sendAmplitudeData(claimOffersClick);
    },
    seedrecommendations: () => {
      sendAmplitudeData(recommendationClick);
    },
    mapview: () => {
      sendAmplitudeData(mapTabClick);
    },
    summaryinstights: () => {
      sendAmplitudeData(titleSyngnetaTechnicalReportsClick);
    },
  };

  const selectMenuItemOnClick = (path: string): void => {
    dispatch(setAnchorId(null));
    const changeMainMenu: Record<string, RouteDTO> = {};
    Object.keys(mainMenu).forEach((ele: string) => {
      if (mainMenu[ele].path === path) {
        if (logSideBarMenuClickAmplitudeEvent[ele] && !mainMenu[ele].select) {
          logSideBarMenuClickAmplitudeEvent[ele]();
        }
        changeMainMenu[ele] = {
          ...mainMenu[ele],
          select: true,
        };
      } else {
        changeMainMenu[ele] = { ...mainMenu[ele], select: false };
      }
    });

    setMainMenu(changeMainMenu);

    const changeRecommendations: Record<string, RouteDTO> = {};
    Object.keys(recommendations).forEach((ele) => {
      if (recommendations[ele].path === path) {
        if (
          logSideBarMenuClickAmplitudeEvent[ele] &&
          !recommendations[ele].select
        ) {
          logSideBarMenuClickAmplitudeEvent[ele]();
        }
        changeRecommendations[ele] = {
          ...recommendations[ele],
          select: true,
        };
      } else {
        changeRecommendations[ele] = { ...recommendations[ele], select: false };
      }
    });
    setRecommendations(changeRecommendations);

    const changeOfferMenu: Record<string, RouteDTO> = {};
    Object.keys(offersMenu).forEach((ele, index) => {
      if (offersMenu[ele].path === path) {
        if (logSideBarMenuClickAmplitudeEvent[ele] && !offersMenu[ele].select) {
          logSideBarMenuClickAmplitudeEvent[ele]();
        }
        changeOfferMenu[ele] = {
          ...offersMenu[ele],
          select: true,
        };
      } else {
        changeOfferMenu[ele] = { ...offersMenu[ele], select: false };
      }
    });
    setOffersMenu(changeOfferMenu);
    if (path === "/createseedrecommendation") {
      if (profileDataSelector?.defaultLocation?.countryCode === "pl")
        history("/vev-page/T1");
      else if (profileDataSelector?.defaultLocation?.countryCode === "hu")
        history("/vev-page/E1");
    } else if (path === "/summaryinsights") {
      history("/mapview", {
        state: { anchorId: "/mapview#insightsfromyourfarm" },
      });
    } else history(path);
  };
  const displayMenus = (menuItems: Record<any, RouteDTO>): JSX.Element => {
    return (
      <div>
        {Object.keys(menuItems).map((ele, index) => {
          return (
            <>
              {menuItems[ele].select && (
                <span
                  className={`side-menu-selected ${
                    index === 0 ? "mf-ml-0" : ""
                  }`}
                ></span>
              )}
              <div
                className={`side-submenu-item ${
                  menuItems[ele].select ? "sidebar-selected-background" : ""
                } ${menuItems[ele]?.icon ? "side-submenu-item-icon-left" : ""}`}
                key={menuItems[ele].path}
              >
                {menuItems[ele].icon && (
                  <img
                    src={menuItems[ele].icon}
                    alt="icon"
                    className="side-submenu-icon-size"
                  />
                )}
                <span className="sidebar_margin_left_5">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      selectMenuItemOnClick(menuItems[ele].path);
                    }}
                    className={`sidebar_button side-menu-text mf-cursor-pointer ${
                      menuItems[ele].select ? "side-menu-item-active" : ""
                    }`}
                  >
                    {translate(menuItems[ele].name)}
                  </button>
                </span>
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const onClickGetNow = (): void => {
    const os = osfunction();
    if (os === "MacOS") window.location.href = APP_APPLE_URI;
    else window.location.href = APP_GOOGLE_URL;
  };
  const onClickProductsAndServices = (): void => {
    if (locations.pathname === "/dashboard")
      window.location.replace("/dashboard#productsandservice");
    else
      history("/dashboard", {
        state: { anchorId: "/dashboard#productsandservice" },
      });
  };
  return (
    <div className="syt-antd-menu-item svg">
      <div>
        <li className="side-menu-banner-li side-menu-user-name">
          {translate(ConstantKey.HI)}{" "}
          {profileDataSelector?.profileData.firstName
            ? profileDataSelector?.profileData.firstName
            : "NA"}
        </li>
        {profileDataSelector?.loyalityPoints && (
          <li className="side-menu-banner-li side-menu-points">
            {profileDataSelector?.loyalityPoints} pts
          </li>
        )}
      </div>
      <div className="side-menu-user">
        <span>
          <img src={locationIcon} alt="location" />
          {profileDataSelector?.userLocation
            ? profileDataSelector?.userLocation
            : "Location not found!"}
        </span>
        <span>
          <img src={notification} alt="notification" />
          {/* <span className="side-menu-notification-number">2</span> */}
        </span>
      </div>
      <div className="side-menu-items">
        <div className="side-menu-banner">
          <div className="side-menu-banner-text">
            <li className="side-menu-banner-li">
              {translate(ConstantKey.DOWNLOAD)}
            </li>
            <li className="side-menu-banner-li">
              {translate(ConstantKey.MY_FIELD_APP)}{" "}
            </li>
            <Button
              type={ButtonType.outline}
              className="banner-button-white"
              size="small"
              onClick={onClickGetNow}
            >
              {translate(ConstantKey.GET_NOW)}
            </Button>

            <img
              src={myfieldapp}
              className="side-menu-banner-image"
              alt="myfieldapp"
            />
          </div>
        </div>
      </div>
      <div className="side-menu-items">{displayMenus(mainMenu)}</div>
      <div className="side-menu-items">
        <div className="side-menu-label">
          <img
            src={myoffersIcon}
            alt="icon"
            className="side-submenu-icon-size"
          />
          <span className="sidebar_margin_left_4">
            {translate(ConstantKey.MY_OFFERS)}
          </span>
        </div>
        {displayMenus(offersMenu)}
      </div>
      <div className="side-menu-items">
        <div className="side-menu-label">
          <img
            src={recommendationsIcon}
            alt="icon"
            className="side-submenu-icon-size"
          />
          <span className="sidebar_margin_left_4">
            {translate(ConstantKey.RECOMMENDATIONS)}
          </span>
        </div>
        {displayMenus(recommendations)}
      </div>
      <div className="side-menu-items sidebar_left">
        <button
          className="sidebar_button side-menu-label sidebar_points"
          onClick={onClickProductsAndServices}
        >
          <img
            src={productsAndServicesIcon}
            alt="icon"
            className="side-submenu-icon-size"
          />
          <span className="sidebar_margin_left_4">
            {translate(ConstantKey.NEW_PRODUCTS_AND_SERVICES)}
          </span>
        </button>
      </div>
      <div className="side-menu-items mf-mb-65">
        <div className="side-menu-label">
          <img
            src={contactsIcon}
            alt="icon"
            className="side-submenu-icon-size"
          />
          <span className="sidebar_margin_left_4">
            {translate(ConstantKey.CONTACT_SYNGENTA)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
