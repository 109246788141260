import LandingScreen from "../LandingScreen/LandingScreen";

export const AppLoader = (): JSX.Element => {
  return (
    <LandingScreen>
      <div className="mf-loader"></div>
    </LandingScreen>
  );
};

export default AppLoader;
