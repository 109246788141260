import { useState, type FC } from "react";
import { Divider } from "syngenta-digital-cropwise-react-ui-kit";

import SettingsDrawer from "./SettingsDrawer";
import iconOnlySquare from "../../../assets/icons/icon-only-square.svg";

export const SettingsNavigation: FC = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="mf-height-100">
      <button
        className="mf-btn-img mf-height-100"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <img
          src={iconOnlySquare}
          className="mf-cursor-pointer mf-mb-10"
          alt="iconOnlySquare"
        />
      </button>
      <Divider type="vertical" className="mf-mb-10" />
      <SettingsDrawer visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default SettingsNavigation;
