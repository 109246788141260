import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  ButtonType,
  AntdConfigProvider,
} from "syngenta-digital-cropwise-react-ui-kit";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import type { ApplicationState } from "../../core/redux/reducers/";
import {
  setBannerDetails,
  setAnchorId,
} from "../../core/redux/reducers/productTemplateSlice";
import ConstantKey from "../../core/locale/strings.json";
import { setRecommendationList } from "../../core/redux/reducers/recommendationsSlice";
import MoreSyngentaTiles from "../moresyngentatiles";
import Banners from "../../shared/components/Banners";
import { BannerType } from "../../core/types/banners.dto";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { exploreWeatherMapClick } from "../../core/utils/analytics/constants";
import mapView from "../../assets/images/mapview.svg";
import ForeCastHome from "../forecastHome";
import "./dashboard.less";

export const AppDashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const ProductTemplate = useSelector(
    (state: ApplicationState) => state.productTemplateDetails
  );
  useEffect(() => {
    if (location?.state?.anchorId) {
      dispatch(setAnchorId(location?.state?.anchorId));
    }
  }, []);
  useEffect(() => {
    if (ProductTemplate?.anchorId && ProductTemplate?.bannerDetails) {
      window.location.replace(ProductTemplate?.anchorId);
    }
  }, [ProductTemplate?.bannerDetails, ProductTemplate?.anchorId]);

  useEffect(() => {
    if (ProfileDataSelector?.defaultLocation?.countryCode) {
      const countryCode =
        ProfileDataSelector.defaultLocation?.countryCode?.toUpperCase();
      let newOffers: any = [];
      let alertsRecommendation: any = [];
      let newProduct: any = [];
      let claimedAndCommittedOffers: any = [];
      ProductTemplateApi.getBannerTemplateApi(countryCode)
        .then((res) => {
          res.data?.content?.forEach((banner: any) => {
            if (
              banner.bannerCategory === "ALERT" ||
              banner.bannerCategory === "RECOMMENDATION" ||
              banner.bannerCategory === "REPORT"
            )
              alertsRecommendation.push(banner);
            else if (banner.bannerCategory === "NEW_OFFER") {
              if (
                banner.offerStatus === "CLAIMED" ||
                banner.offerStatus === "COMMITTED"
              )
                claimedAndCommittedOffers.push(banner);
              else newOffers.push(banner);
            } else if (
              banner.bannerCategory === "NEW_PRODUCT" ||
              banner.bannerCategory === "SERVICES"
            )
              newProduct.push(banner);
          });
          newOffers = newOffers?.sort((a: any, b: any) => {
            if (a.weight < b.weight) return 1;
            else if (b.weight < a.weight) return -1;
            return 0;
          });
          alertsRecommendation = alertsRecommendation?.sort(
            (a: any, b: any) => {
              if (a.weight < b.weight) return 1;
              else if (b.weight < a.weight) return -1;
              return 0;
            }
          );
          newProduct = newProduct?.sort((a: any, b: any) => {
            if (a.weight < b.weight) return 1;
            else if (b.weight < a.weight) return -1;
            return 0;
          });
          claimedAndCommittedOffers = claimedAndCommittedOffers?.sort(
            (a: any, b: any) => {
              if (a.weight < b.weight) return 1;
              else if (b.weight < a.weight) return -1;
              return 0;
            }
          );

          const banners = {
            newOffers,
            alertsRecommendation,
            newProduct,
            claimedAndCommittedOffers,
          };
          dispatch(setBannerDetails(banners));
        })
        .catch(() => {});
    }
  }, [ProfileDataSelector?.profileData]);
  useEffect(() => {
    if (ProfileDataSelector?.profileData?.email)
      ProductTemplateApi.getUserRecommendations(
        ProfileDataSelector?.profileData?.email,
        0,
        20
      )
        .then((response) => {
          if (response?.data) {
            dispatch(setRecommendationList(response?.data));
          }
        })
        .catch(() => {});
  }, [ProductTemplate?.bannerDetails]);
  const onClickButton = (): void => {
    sendAmplitudeData(exploreWeatherMapClick);
    navigate("/mapview");
  };

  return (
    <AntdConfigProvider>
      <div className="mf-grid-story-container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={15} xl={15}>
            <div className="mapview-container">
              <img src={mapView} alt="weatherMapStatic" className="mapview" />
              <Button
                onClick={onClickButton}
                className="mapview-button"
                type={ButtonType.primary}
              >
                {translate(ConstantKey.EXPLORE_WEATHER_MAP)}
              </Button>
            </div>
            {ProductTemplate?.bannerDetails?.newOffers?.length !== 0 && (
              <Banners
                title={translate(ConstantKey.NEW_OFFERS)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundColor}
                data={
                  ProductTemplate?.bannerDetails?.newOffers
                    ? ProductTemplate?.bannerDetails?.newOffers
                    : []
                }
              />
            )}
            {ProductTemplate?.bannerDetails?.claimedAndCommittedOffers
              ?.length !== 0 && (
              <Banners
                title={translate(ConstantKey.COMMITTED_AND_CLAIMED_OFFERS)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.claimedAndCommittedOffers
                    ? ProductTemplate?.bannerDetails?.claimedAndCommittedOffers
                    : []
                }
              />
            )}
            {ProductTemplate?.bannerDetails?.alertsRecommendation?.length !==
              0 && (
              <Banners
                title={translate(ConstantKey.RECOMMENDATIONS_AND_REPORTS)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.alertsRecommendation
                    ? ProductTemplate?.bannerDetails?.alertsRecommendation
                    : []
                }
              />
            )}
            <MoreSyngentaTiles />
            <div id="productsandservice">
              {ProductTemplate?.bannerDetails?.newProduct?.length !== 0 && (
                <Banners
                  title={translate(ConstantKey.new_products_and_services)}
                  buttonType={ButtonType.outline}
                  bannerType={BannerType.backgroundImage}
                  data={
                    ProductTemplate?.bannerDetails?.newProduct
                      ? ProductTemplate?.bannerDetails?.newProduct
                      : []
                  }
                />
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <ForeCastHome />
          </Col>
        </Row>
      </div>
    </AntdConfigProvider>
  );
};

export default AppDashboard;
