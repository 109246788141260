import { createSlice } from "@reduxjs/toolkit";

export interface MapviewState {
  fieldslist: any;
  fieldsDetails: any;
  insightsReportData: any;
  allCrops: any;
  cropdiseaserisk: any;
}
const initialState: MapviewState = {
  fieldslist: null,
  fieldsDetails: null,
  insightsReportData: null,
  allCrops: null,
  cropdiseaserisk: null,
};
export const mapviewSlice = createSlice({
  name: "mapview Details",
  initialState,
  reducers: {
    setFieldsList: (state, action) => {
      return {
        ...state,
        fieldslist: action.payload,
      };
    },
    setFieldsDetails: (state, action) => {
      return {
        ...state,
        fieldsDetails: action.payload,
      };
    },
    setInsightsReportData: (state, action) => {
      return {
        ...state,
        insightsReportData: action.payload,
      };
    },
    setAllCrops: (state, action) => {
      return {
        ...state,
        allCrops: action.payload,
      };
    },
    setCropDiseases: (state, action) => {
      return {
        ...state,
        cropdiseaserisk: action.payload,
      };
    },
  },
});
export const {
  setFieldsDetails,
  setFieldsList,
  setInsightsReportData,
  setAllCrops,
  setCropDiseases,
} = mapviewSlice.actions;
export default mapviewSlice.reducer;
