import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import type { LegalDocsSidebarType } from "../../core/types/accountProfile.dto";
import cropwiselogo from "../../assets/icons/myfield-icon.svg";
import sidebarMenuIcon from "../../assets/images/sidebarmenu.svg";
import privacyIcon from "../../assets/images/privacy.svg";
import CustomMenu from "../../shared/components/menu";
import ConstantKey from "../../core/locale/strings.json";

const LegalDocsSidebar: FC<LegalDocsSidebarType> = ({
  selectedTab,
  countryList,
  collapsedMenu,
  setCollapsedMenu,
  SearchBar,
  selectedCountry,
  handleCountryChange,
  handleTabChange,
  renderLanguageLabel,
}: LegalDocsSidebarType): JSX.Element => {
  const { t: translate } = useTranslation();

  const handleTabClick = (selectedTab: string): void => {
    handleTabChange(selectedTab);
    if (window.innerWidth < 992) {
      setCollapsedMenu(true);
    }
  };

  const handleLanguageChange = (): void => {
    window.innerWidth < 992 ? setCollapsedMenu(true) : setCollapsedMenu(false);
  };

  return (
    <div className="legal-docs__sidebar">
      <img
        src={cropwiselogo}
        alt="cropwise logo"
        className="legal-docs__logo"
      />
      {!collapsedMenu && (
        <>
          <button
            data-testid="legal-docs-closeicon"
            className="close-icon-wrapper"
            onClick={() => {
              setCollapsedMenu(true);
            }}
          >
            <CloseOutlined />
          </button>
          <p className="legal-documents-title sidebar">
            {translate(ConstantKey.LEGAL_DOCUMENTS)}
          </p>
        </>
      )}
      <div className="legal-docs_sidebar_menu_wrapper">
        <button
          data-testid="tcSidebarBtn"
          className={`legal-docs_sidebar_menu ${
            selectedTab === "TC" ? "doc-selected" : ""
          }`}
          onClick={() => {
            handleTabClick("TC");
          }}
        >
          <img src={sidebarMenuIcon} alt="menu" />
          <p className="memu-title">
            {translate(ConstantKey.TERMS_AND_CONDITIONS)}
          </p>
        </button>
        <button
          data-testid="pnSidebarBtn"
          className={`legal-docs_sidebar_menu ${
            selectedTab === "PN" ? "doc-selected" : ""
          }`}
          onClick={() => {
            handleTabClick("PN");
          }}
        >
          <img src={privacyIcon} alt="privacy" />
          <p className="memu-title">{translate(ConstantKey.PRIVACY_NOTICE)}</p>
        </button>
      </div>
      <div className="legal-documents-search sidebar">
        <SearchBar
          countryList={countryList}
          selectedCountry={selectedCountry}
          handleCountryChange={handleCountryChange}
        />
      </div>
      <CustomMenu
        disabledOverflow
        isUsingDefaultOnSelect
        customLanguageLabel={renderLanguageLabel}
        className="language-selection-menu sidebar"
        onSelect={handleLanguageChange}
      />
    </div>
  );
};

export default LegalDocsSidebar;
