import React from "react";

export const EditOrganization = (): JSX.Element => {
  const workSpaceId = process.env.REACT_APP_WORKSPACE_ID ?? "";
  const organizationId = "62d4f9e1-172f-47e8-b7f3-7719fcfb9d5c";
  const createOrgDeepLink = `${
    process.env.REACT_APP_CROPWISE_APP_URL ?? ""
  }/deep-actions/organization?workspace_id=${workSpaceId}&&org_id=${organizationId}&callback_uri=${
    process.env.REACT_APP_URL
  }/deep-action`;

  React.useEffect(() => {
    window.location.href = createOrgDeepLink;
  }, [createOrgDeepLink]);

  return <>Redirecting to edit organization....</>;
};

export default EditOrganization;
