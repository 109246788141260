import { useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import type { ApplicationState } from "../../core/redux/reducers";
import { setRecommendationList } from "../../core/redux/reducers/recommendationsSlice";
import ConstantKey from "../../core/locale/strings.json";
import {
  UAT_RECOMMENDATIONS,
  PROD_RECOMMENDATIONS,
  UAT_RECOMMENDATIONS_DETAILS,
  PROD_RECOMMENDATIONS_DETAILS,
} from "../../core/constants/url";
import Pagination from "../../shared/components/Pagination/Pagination";
import "./myrecommendations.less";
const MyRecommendations = (): JSX.Element => {
  const uid = useId();
  const dispatch = useDispatch();
  const [apiFailed, setApiFailed] = useState(null as null | boolean);
  const [loader, setLoader] = useState(false as boolean);
  const [itemCount, setItemCount] = useState(0);
  const { t: translate } = useTranslation();
  const RecommendationsDetails = useSelector(
    (state: ApplicationState) => state.recommendationsDetails
  );
  const ProfileData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  useEffect(() => {
    if (ProfileData?.profileData?.email) onClickPagination(0);
  }, [ProfileData?.profileData]);
  const onClickPagination = (page: number): void => {
    setLoader(true);
    if (ProfileData?.profileData?.email)
      ProductTemplateApi.getUserRecommendations(
        ProfileData?.profileData?.email,
        page * 20,
        20
      )
        .then((response) => {
          if (response?.data) {
            dispatch(setRecommendationList(response?.data));
            setItemCount(page * 20 + 1);
            setApiFailed(false);
          }
        })
        .catch(() => {
          setApiFailed(true);
        })
        .finally(() => {
          setLoader(false);
        });
    window.scrollTo(0, 0);
  };
  const onClickCreateButton = (): void => {
    const userLanguage = localStorage.getItem("userLanguage") ?? "en";
    if (process.env.REACT_APP_ENV === "prod") {
      const urlProd = PROD_RECOMMENDATIONS(userLanguage);
      window.open(urlProd, "_blank");
    } else {
      const urlUat = UAT_RECOMMENDATIONS(userLanguage);
      window.open(urlUat, "_blank");
    }
  };
  const onClickViewRecommendation = (id: string): void => {
    const userLanguage = localStorage.getItem("userLanguage") ?? "en";
    if (process.env.REACT_APP_ENV === "prod")
      window.open(
        `${PROD_RECOMMENDATIONS_DETAILS}/recommendation/${id}?lang=${userLanguage}`,
        "_blank"
      );
    else
      window.open(
        `${UAT_RECOMMENDATIONS_DETAILS}/recommendation/${id}?lang=${userLanguage}`,
        "_blank"
      );
  };
  return (
    <div>
      {loader && <div className="mf-loader"></div>}
      <div className="myrecommendations_title">
        <div>
          <div className="myrecommendations_maintitle">
            {translate(ConstantKey.MY_SEED_RECOMMENDATIONS)}
          </div>
          <div className="myrecommendations_subtitle">
            {translate(ConstantKey.FROM_CROPWISE_SEED_SELECTOR)}
          </div>
        </div>
        <Button
          type={ButtonType.outline}
          onClick={onClickCreateButton}
          className="createrecommendation_button"
        >
          {translate(ConstantKey.CREATE_NEW_RECOMMENATION)}
        </Button>
      </div>
      {RecommendationsDetails?.recommendationList
        ?.totalRecommedationIdsCount !== 0
        ? RecommendationsDetails?.recommendationList?.recommedationIds?.map(
            (list: any, index: number) => {
              return (
                <div key={uid} className="recommendation_body">
                  <div className="recommendation_name">{`${translate(
                    ConstantKey.RECOMMENDATION
                  )} ${itemCount + index}`}</div>
                  <Button
                    type={ButtonType.primary}
                    onClick={() => {
                      onClickViewRecommendation(list);
                    }}
                    data-testid="viewfullrecommendation"
                    className="recommendation_viewfull"
                  >
                    {translate(ConstantKey.VIEW_FULL_RECOMMENDATION)}
                  </Button>
                </div>
              );
            }
          )
        : RecommendationsDetails?.recommendationList
            ?.totalRecommedationIdsCount === 0 && (
            <div className="recommendation_body recommendation_bold">
              {translate(ConstantKey.RECOMMENDATIONS_NOT_AVAILABLE)}
            </div>
          )}

      {apiFailed && (
        <div className="recommendation_body recommendation_bold">
          {translate(ConstantKey.SERVICE_IS_UNAVAILABLE)}
        </div>
      )}
      {RecommendationsDetails?.recommendationList &&
        RecommendationsDetails?.recommendationList?.totalRecommedationIdsCount >
          20 && (
          <div className="myrecommendations_title">
            <Pagination
              onClickPagination={onClickPagination}
              total={
                RecommendationsDetails?.recommendationList
                  ?.totalRecommedationIdsCount
              }
            />
          </div>
        )}
    </div>
  );
};
export default MyRecommendations;
