import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  ButtonType,
  Col,
  Divider,
  Row,
  Switch,
  notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../core/locale/strings.json";
import NotificationPreferencesAPI from "../../core/api/notificationPreferencesAPI";
import {
  type UserPreferencesDTO,
  type UserPreferencesReducerActionDTO,
  UserPreferencesTypeEnum,
} from "../../core/types/accountProfile.dto";
import type { ApplicationState } from "../../core/redux/reducers";

import SmsIcon from "../../assets/icons/smsIcon.svg";
import PhoneIcon from "../../assets/icons/phoneIcon.svg";
import emailIcon from "../../assets/icons/emailIcon.svg";

import "./preferences.less";

export function preferencesReducer(
  state: UserPreferencesDTO,
  action: UserPreferencesReducerActionDTO
): any {
  switch (action.type) {
    case "AGRONOMIC_EMAIL":
      return { ...state, agronomicEmail: action.payload };
    case "AGRONOMIC_SMS":
      return { ...state, agronomicSms: action.payload };
    case "AGRONOMIC_APP":
      return { ...state, agronomicApp: action.payload };
    case "COMMERCIAL_EMAIL":
      return { ...state, commercialEmail: action.payload };
    case "COMMERCIAL_APP":
      return { ...state, commercialApp: action.payload };
    case "ALL":
      return { ...action.payload };
    default:
      throw new Error();
  }
}

export const initialFormState: UserPreferencesDTO = {
  agronomicEmail: false,
  agronomicSms: false,
  agronomicApp: false,
  commercialEmail: false,
  commercialApp: false,
};

export const Preferences = (): JSX.Element => {
  const { t: translate } = useTranslation();

  const [state, dispatch] = useReducer(preferencesReducer, initialFormState);

  const [loader, setLoader] = useState<boolean>(false);

  const existingPreferences = useRef<UserPreferencesDTO>(initialFormState);

  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  useEffect(() => {
    if (profileDataSelector?.profileData?.growerRefId) {
      fetchPreferenceDetails(profileDataSelector?.profileData?.growerRefId);
    }
  }, [profileDataSelector?.profileData?.growerRefId]);

  const onSwitchUpdate = useCallback(
    (actionType: UserPreferencesTypeEnum, isChecked: boolean) => {
      dispatch({
        type: actionType,
        payload: isChecked,
      });
    },
    []
  );

  const fetchPreferenceDetails = useCallback((growerRefId: string) => {
    setLoader(true);
    NotificationPreferencesAPI.getPreferences(growerRefId)
      .then((response) => {
        if (response.status === 200 && response.data) {
          existingPreferences.current = response.data;
          dispatch({
            type: UserPreferencesTypeEnum.ALL,
            payload: response.data,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const onSubmit = useCallback(() => {
    if (profileDataSelector?.profileData?.growerRefId && state) {
      setLoader(true);
      NotificationPreferencesAPI.updatePreferences(
        profileDataSelector?.profileData?.growerRefId,
        state
      )
        .then((response) => {
          if (response.status === 200 && response.data) {
            existingPreferences.current = response.data;
            dispatch({
              type: UserPreferencesTypeEnum.ALL,
              payload: response.data,
            });
            const message = translate(ConstantKey.PREFERENCES_UPDATED_SUCCESS);
            notification.success({
              message,
              description: "",
              placement: "topRight",
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: error?.cause?.data?.detail
              ? error?.cause?.data?.detail
              : `${translate(ConstantKey.SOMETHING_WENT_WRONG)}!`,
            description: "",
            placement: "topRight",
          });
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [state, profileDataSelector?.profileData?.growerRefId]);

  const onReset = useCallback(() => {
    dispatch({
      type: UserPreferencesTypeEnum.ALL,
      payload: existingPreferences.current,
    });
  }, [state]);

  return (
    <div className="mf-grid-story-container preference-page">
      {loader && <div className="mf-loader"></div>}
      <div className="preferences-header mf-mb-10">
        <h4 className="mf-page-title">{translate(ConstantKey.PREFERENCES)}</h4>
      </div>
      <div className="preferences-container">
        <div className="preferences-container-title">
          {translate(ConstantKey.ALERT_PREFERENCES_TITLE)}
        </div>
        <div className="preferences-container-sub-title">
          {translate(ConstantKey.ALERT_PREFERENCES_SUB_TITLE)}
        </div>
        <Divider />
        {/* Agronomics content */}
        <div>
          <div className="preferences-container-title">
            {translate(ConstantKey.AGRONOMIC_CONTENT)}
          </div>
          <div className="preferences-container-sub-title">
            {translate(ConstantKey.AGRONOMIC_CONTENT_SUB_TITLE)}
          </div>
          <Row className="all-switch-containers">
            <Col className="switch-container mf-mr-50" lg={4}>
              <img
                src={emailIcon}
                className="preference-icon"
                alt="email-icon"
              />
              <h5 className="mf-ml-10 mf-mr-10">
                {translate(ConstantKey.EMAIL)}
              </h5>
              <Switch
                data-testid="agronomic-content-email-switch"
                checked={state.agronomicEmail}
                onChange={(event) => {
                  onSwitchUpdate(
                    UserPreferencesTypeEnum.AGRONOMIC_EMAIL,
                    event
                  );
                }}
                size="medium"
              />
            </Col>
            <Col className="switch-container mf-mr-50" lg={4}>
              <img src={SmsIcon} className="preference-icon" alt="sms-icon" />
              <h5 className="mf-ml-10 mf-mr-10">
                {translate(ConstantKey.SMS)}
              </h5>
              <Switch
                data-testid="agronomic-content-sms-switch"
                checked={state.agronomicSms}
                onChange={(event) => {
                  onSwitchUpdate(UserPreferencesTypeEnum.AGRONOMIC_SMS, event);
                }}
                size="medium"
              />
            </Col>
            <Col className="switch-container" lg={4}>
              <img
                src={PhoneIcon}
                className="preference-icon"
                alt="app-notification-icon"
              />
              <h5 className="mf-ml-10 mf-mr-10">
                {translate(ConstantKey.APP_NOTIFICATIONS)}
              </h5>
              <Switch
                data-testid="agronomic-content-app-notification-switch"
                checked={state.agronomicApp}
                onChange={(event) => {
                  onSwitchUpdate(UserPreferencesTypeEnum.AGRONOMIC_APP, event);
                }}
                size="medium"
              />
            </Col>
          </Row>
        </div>
        {/* Commercial content */}
        <div className="mf-mt-50">
          <div className="preferences-container-title">
            {translate(ConstantKey.COMERCIAL_CONTENT)}
          </div>
          <div className="preferences-container-sub-title">
            {translate(ConstantKey.COMERCIAL_CONTENT_SUB_TITLE)}
          </div>
          <Row className="all-switch-containers">
            <Col className="switch-container mf-mr-50" lg={4}>
              <img
                src={emailIcon}
                className="preference-icon"
                alt="email-icon"
              />
              <h5 className="mf-ml-10 mf-mr-10">
                {translate(ConstantKey.EMAIL)}
              </h5>
              <Switch
                data-testid="commercial-content-email-switch"
                checked={state.commercialEmail}
                onChange={(event) => {
                  onSwitchUpdate(
                    UserPreferencesTypeEnum.COMMERCIAL_EMAIL,
                    event
                  );
                }}
                size="medium"
              />
            </Col>
            <Col className="switch-container" lg={4}>
              <img
                src={PhoneIcon}
                className="preference-icon"
                alt="app-notification-icon"
              />
              <h5 className="mf-ml-10 mf-mr-10">
                {translate(ConstantKey.APP_NOTIFICATIONS)}
              </h5>
              <Switch
                data-testid="acommercial-content-app-notification-switch"
                checked={state.commercialApp}
                onChange={(event) => {
                  onSwitchUpdate(UserPreferencesTypeEnum.COMMERCIAL_APP, event);
                }}
                size="medium"
              />
            </Col>
          </Row>
        </div>
        <div className="preferences-footer mf-mt-50">
          <Button
            type={ButtonType.default}
            className="mf-mr-10 preferences-action-btn"
            onClick={onReset}
          >
            {translate(ConstantKey.RESET)}
          </Button>
          <Button
            type={ButtonType.primary}
            className="preferences-action-btn"
            onClick={onSubmit}
          >
            {translate(ConstantKey.SAVE)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
