import { createSlice } from "@reduxjs/toolkit";

export interface ForecastInitialState {
  forecastWeeklyData: any;
  forecastHourlyTodayData: any;
}
const initialState: ForecastInitialState = {
  forecastWeeklyData: null,
  forecastHourlyTodayData: null,
};
export const forecastSlice = createSlice({
  name: "forecast Details",
  initialState,
  reducers: {
    setForcastWeeklyData: (state, action) => {
      return {
        ...state,
        forecastWeeklyData: action.payload,
      };
    },
    setForecastHourlyTodayData: (state, action) => {
      return {
        ...state,
        forecastHourlyTodayData: action.payload,
      };
    },
  },
});
export const { setForcastWeeklyData, setForecastHourlyTodayData } =
  forecastSlice.actions;
export default forecastSlice.reducer;
