import { useState } from "react";
import { Pagination as AntPagination } from "syngenta-digital-cropwise-react-ui-kit";
import "./pagination.less";
interface PaginationProps {
  total: number;
  onClickPagination: any;
}
const Pagination = ({
  total,
  onClickPagination,
}: PaginationProps): JSX.Element => {
  const [current, setCurrent] = useState(1);
  const onChange = (e: number): void => {
    onClickPagination(e - 1);
    setCurrent(e);
  };
  return (
    <div className="mf-pagination">
      <AntPagination
        responsive={true}
        onChange={onChange}
        current={current}
        defaultCurrent={1}
        defaultPageSize={20}
        total={total}
      />
    </div>
  );
};
export default Pagination;
