import winddirectionIcons from "../../assets/winddirectionIcons";
import weatherImages from "../../assets/weatherImages";

export const getDirectionIcons = (windIocn: string): any => {
  let direction: null | string = null;
  if (windIocn === "E") direction = "east";
  else if (windIocn === "SE") direction = "southeast";
  else if (windIocn === "NE") direction = "northeast";
  else if (windIocn === "N") direction = "north";
  else if (windIocn === "NW") direction = "northwest";
  else if (windIocn === "W") direction = "west";
  else if (windIocn === "S") direction = "south";
  else if (windIocn === "SW") direction = "southwest";
  return winddirectionIcons[direction as keyof typeof winddirectionIcons];
};

export const getIconSVGFile = (pictoCode: string): string => {
  const iconName = `cloud${pictoCode}`;
  const icon = weatherImages[iconName as keyof typeof weatherImages];
  if (icon === undefined) {
    throw new Error("Invalid icon: " + pictoCode);
  }
  return icon;
};

export const getNightOrDayHourly = (time: string): string => {
  let iconName = "hourlyNightCloud";
  try {
    if (time) {
      const split = time?.split(":") ?? null;
      if (split) {
        const currentHour = parseInt(split[0], 10);
        if (currentHour >= 6 && currentHour < 18) iconName = "hourlyCloud";
      }
    }
  } catch (error) {
    console.error("ERROR: getNightOrDayHourly ", error);
  }
  return iconName;
};

export const getHourlyForeCastIcon = (
  time: string,
  pictoCode: string
): string => {
  let iconName = getNightOrDayHourly(time);
  iconName = iconName + pictoCode;
  const icon = weatherImages[iconName as keyof typeof weatherImages];
  if (icon === undefined) {
    throw new Error("Invalid hourly icon: " + pictoCode);
  }
  return icon;
};
