import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../core/locale/strings.json";
import LandingScreen from "../../shared/components/LandingScreen/LandingScreen";

import type { ApplicationState } from "../../core/redux/reducers";

export const EditField = (): JSX.Element => {
  const profileData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (profileData?.defaultOrgId && profileData?.defaultPropertyId) {
      const deepLink = `${
        process.env.REACT_APP_CROPWISE_APP_URL ?? ""
      }/deep-actions/create-field?org_id=${
        profileData?.defaultOrgId
      }&property_id=${profileData?.defaultPropertyId}&callback_uri=${
        process.env.REACT_APP_URL
      }/mapview`;
      window.location.href = deepLink;
    } else {
      navigate("/mapview", { replace: true });
    }
  }, [profileData]);

  return (
    <LandingScreen>
      <div>
        <p className="mf-loader-text">
          {translate(ConstantKey.REDIRET_TO_MANAGE_FIELD)}....
        </p>
        <div className="mf-loader"></div>
      </div>
    </LandingScreen>
  );
};

export default EditField;
