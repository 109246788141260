import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { initCWElements, type CWEnv } from "@cw-elements/config";
import "syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less";
import { store } from "./core/redux/store";
import { getAccessToken } from "./core/utils/common-function";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initAmplitude } from "./core/utils/analytics";
import App from "./App";
import "./index.less";

initAmplitude();
const initClients = (): void => {
  let tokens;
  let env: CWEnv = "staging";
  try {
    const variable = process.env.REACT_APP_CROPWISE_ENV;
    tokens = getAccessToken();
    env = variable as CWEnv;
  } catch (ignored) {
    tokens = "";
  }
  initCWElements({
    environment: env,
    token: tokens,
    hostAppId: process.env.REACT_APP_BASE_REF_ID,
  });
};

initClients();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
