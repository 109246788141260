import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type {
  FieldListDto,
  FieldDetailsInitialStateDto,
  FieldDetailListDto,
} from "../../types/fieldDetails.dto";

const initialState: FieldDetailsInitialStateDto = {
  loading: false,
  fieldIds: null,
  fieldList: null,
  fieldDetailList: null,
  selectedField: null,
  defaultSeasonDetails: null,
  parentRegionId: null,
};

export const fieldDetailsSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    reset: () => initialState,
    setParentRegionId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        parentRegionId: action.payload,
      };
    },
    setFieldIds: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        fieldIds: [...action.payload],
      };
    },
    setFieldList: (state, action: PayloadAction<FieldListDto[]>) => {
      return {
        ...state,
        fieldList: [...action.payload],
      };
    },
    setFieldDetailList: (
      state,
      action: PayloadAction<FieldDetailListDto[]>
    ) => {
      return {
        ...state,
        fieldDetailList: [...action.payload],
      };
    },
    setSelectedField: (state, action) => {
      return {
        ...state,
        selectedField: [...action.payload],
      };
    },
    setDefaultSeasonDetails: (state, action) => {
      return {
        ...state,
        defaultSeasonDetails: { ...action.payload },
      };
    },
  },
});

export const {
  reset,
  setFieldIds,
  setFieldList,
  setFieldDetailList,
  setSelectedField,
  setDefaultSeasonDetails,
  setParentRegionId,
} = fieldDetailsSlice.actions;
export default fieldDetailsSlice.reducer;
