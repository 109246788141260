import type { NewFieldRequestPayload } from "../types/fieldDetails.dto";
import requests from "./axios";

const PropertiesApi = {
  getPropertyByOrgId: async (orgId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/properties/?orgId=${orgId}`
    ),
  getFieldByPropertyId: async (propertyId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/properties/${propertyId}/fields`
    ),
  fetchFieldData: async (fieldIds: string[]): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/fields/retrieve`,
      {
        ids: fieldIds,
      }
    ),
  createNewField: async (payload: NewFieldRequestPayload): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/fields/`,
      payload
    ),
  getAllCrops: async (countrycode: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/crops/?countryCode=${countrycode}`
    ),
  getCropDiseaseRiskInfo: async (cropRefId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/disease-risk?cropRefId=${cropRefId}`
    ),
  getCropPrediction: async (postdata: any): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/cgc/prediction?start_date=${postdata.start_date}&end_date=${postdata.end_date}&longitude=${postdata.longitude}&latitude=${postdata.latitude}`
    ),
  getGrowthStage: async (postdata: any): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/dssat/prediction?crop=${postdata.crop}&planting_date=${postdata.planting_date}&longitude=${postdata.longitude}&latitude=${postdata.latitude}&country_code=${postdata.country_code}`
    ),
  getByduModel: async (postdata: any): Promise<any> =>
    await requests.post(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/bydv/prediction?crop_emergence_date=${postdata.cropEmergenceDate}&longitude=${postdata.longitude}&latitude=${postdata.latitude}`
    ),
  getHeatStressData: async (long: number, lat: number): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/disease-risk/heatstress?longitude=${long}&latitude=${lat}`
    ),
};
export default PropertiesApi;
