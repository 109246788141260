import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../core/locale/strings.json";
import { setCookie } from "../../core/utils/common-function";

import LandingScreen from "../../shared/components/LandingScreen/LandingScreen";

import "./splashScreen.less";

export const SplashScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const navigateLogin = (): void => {
    setCookie("authAction", "signin", 1);
    navigate("/signin");
  };
  const navigateSignUp = (): void => {
    setCookie("authAction", "signup", 1);
    navigate("/signin");
  };

  return (
    <LandingScreen>
      <div className="login-logout-container">
        <div className="myfield-welcome-message">
          {translate(ConstantKey.WELCOME_MY_FIELD)}
        </div>
        <button className="splash-login-btn" onClick={navigateLogin}>
          {translate(ConstantKey.SIGN_IN)}
        </button>
        <div className="splash-signup">
          {translate(ConstantKey.DONOT_HAVE_ACCOUNT)}{" "}
          <button className="button-link" onClick={navigateSignUp}>
            {translate(ConstantKey.CREATE_YOUR_ACCOUNT)}
          </button>
        </div>
      </div>
    </LandingScreen>
  );
};

export default SplashScreen;
