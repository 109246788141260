import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../core/locale/strings.json";
import {
  METEOBLUE_WEATHER_URL_BASE,
  METEOBLUE_WEATHER_URL_WIDGET_PATH,
  METEOBLUE_WEATHER_URL_CONFIG,
} from "../../core/constants/url";
import type { ApplicationState } from "../../core/redux/reducers";

import HourlyCarousel from "../mapView/HourlyCarousel";

import "./../mapView/mapView.less";

export const MeteoBlueMap = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const profileData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );

  const [currentLng, setCurrentLng] = useState("en");
  const [defaultLocation, setDefaultLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [mapPluginIframeUrl, setMapPluginIframeUrl] = useState("");

  useEffect(() => {
    const currentLanguage = localStorage.getItem("userLanguage");
    if (currentLanguage) {
      setCurrentLng(currentLanguage);
    }
  }, [homePageState?.currentLanguage]);

  useEffect(() => {
    if (
      profileData?.defaultPropertyCoordinates?.lat &&
      profileData?.defaultPropertyCoordinates?.lng
    ) {
      setDefaultLocation(profileData?.defaultPropertyCoordinates);
    }
  }, [profileData?.defaultPropertyCoordinates]);

  useEffect(() => {
    setMapPluginIframeUrl(
      `${METEOBLUE_WEATHER_URL_BASE}${currentLng}${METEOBLUE_WEATHER_URL_WIDGET_PATH}12/${defaultLocation.lat}/${defaultLocation.lng}${METEOBLUE_WEATHER_URL_CONFIG}`
    );
  }, [currentLng, defaultLocation]);

  return (
    <div className="mf-grid-story-container mf-mb-10 map-view-page">
      <div className="mapview-header">
        <div className="mapview-header-left">
          <h4
            className="mf-page-title mf-mb-15"
            data-testid="meteoblue-mapview-title"
          >
            {translate(ConstantKey.WEATHER_METEOBLUE_MAPVIEW)}
          </h4>
        </div>
      </div>
      <div className="mf-width-100" key={mapPluginIframeUrl}>
        {mapPluginIframeUrl && (
          <iframe
            className="meteoblue-map-iframe"
            title="meteoblue-map-iframe"
            key={mapPluginIframeUrl}
            src={mapPluginIframeUrl}
            width="100%"
            sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        )}
      </div>
      <HourlyCarousel />
    </div>
  );
};

export default MeteoBlueMap;
