import { createSlice } from "@reduxjs/toolkit";

export interface HomePageInitialState {
  syngentaProductsDetails: any;
  currentLanguage: string;
}
const initialState: HomePageInitialState = {
  syngentaProductsDetails: null,
  currentLanguage: "en",
};
export const homePageSlice = createSlice({
  name: "home page data",
  initialState,
  reducers: {
    setSyngentaProductsDetails: (state, action) => {
      return {
        ...state,
        syngentaProductsDetails: action.payload,
      };
    },
    setCurrentLanguage: (state, action) => {
      return {
        ...state,
        currentLanguage: action.payload,
      };
    },
  },
});
export const { setSyngentaProductsDetails, setCurrentLanguage } =
  homePageSlice.actions;
export default homePageSlice.reducer;
