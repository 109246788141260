import { type FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "syngenta-digital-cropwise-react-ui-kit";
import { type ApplicationState } from "../../../core/redux/reducers";
import ConstantKey from "../../../core/locale/strings.json";
import { setCurrentLanguage } from "../../../core/redux/reducers/homePageSlice";
import i18n from "../../../Localization/i18";
import { type CustomMenuType } from "../../../core/types/accountProfile.dto";

const CustomMenu: FC<CustomMenuType> = ({
  customLanguageLabel,
  className,
  dataTestId,
  mode,
  items,
  selectedKeys,
  onSelect,
  isUsingDefaultOnSelect,
  disabledOverflow,
  triggerSubMenuAction,
}: CustomMenuType) => {
  const dispatch = useDispatch();
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );

  const languageLabels: Record<string, string> = {
    en: ConstantKey.ENGLISH,
    pl: ConstantKey.POLSKI,
    hu: ConstantKey.HUNGARIAN,
    ro: ConstantKey.ROMANIAN,
  };

  const renderLanguageLabel = (): JSX.Element => {
    return (
      <div
        className="custom-language-menu-title sidebar"
        data-testid={`custom-language-menu-title-${
          languageLabels[homePageState?.currentLanguage as string]
        }`}
      >
        {languageLabels[homePageState?.currentLanguage as string]}
      </div>
    );
  };

  const defaultFunctionForOnSelect = (selectedLanguage: {
    key: string;
  }): void => {
    dispatch(setCurrentLanguage(selectedLanguage.key));
    localStorage.setItem("userLanguage", selectedLanguage.key);
    i18n.changeLanguage(selectedLanguage.key).catch((_error) => {});
  };

  const handleLanguageSelection = (selectedLanguage: { key: string }): void => {
    if (onSelect) {
      onSelect(selectedLanguage);
      if (isUsingDefaultOnSelect) {
        defaultFunctionForOnSelect(selectedLanguage);
      }
    } else {
      defaultFunctionForOnSelect(selectedLanguage);
    }
  };

  const languageMenuItems = [
    {
      key: "language-submenu",
      popupClassName: "language-submenu",
      label: customLanguageLabel
        ? customLanguageLabel()
        : renderLanguageLabel(),
      children: Object.keys(languageLabels).map((language: string) => {
        return {
          key: language,
          label: languageLabels[language],
        };
      }),
    },
  ];

  return (
    <Menu
      onSelect={handleLanguageSelection}
      selectedKeys={selectedKeys ?? [homePageState?.currentLanguage as string]}
      className={className ?? "language-selection-menu"}
      data-testid={dataTestId ?? "language-selection-menu"}
      items={items ?? languageMenuItems}
      mode={mode ?? "horizontal"}
      disabledOverflow={disabledOverflow ?? false}
      triggerSubMenuAction={triggerSubMenuAction ?? "click"}
    />
  );
};

export default CustomMenu;
