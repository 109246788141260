import React from "react";

export const CreateProperty = (): JSX.Element => {
  const organizationId = "62d4f9e1-172f-47e8-b7f3-7719fcfb9d5c";
  const createOrgDeepLink = `${
    process.env.REACT_APP_CROPWISE_APP_URL ?? ""
  }/deep-actions/create-property?org_id=${organizationId}&callback_uri=${
    process.env.REACT_APP_URL
  }/deep-action&state={state}`;

  React.useEffect(() => {
    window.location.href = createOrgDeepLink;
  }, [createOrgDeepLink]);

  return <>Redirecting to create property/farm ....</>;
};

export default CreateProperty;
