import requests from "./axios";
import type { PreferencesDTO } from "../types/preferences.dto";

const NotificationPreferencesAPI = {
  getPreferences: async (growerRefId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/notification-preferences/${growerRefId}`
    ),
  updatePreferences: async (
    growerRefId: string,
    payload: PreferencesDTO
  ): Promise<any> =>
    await requests.put(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/notification-preferences/${growerRefId}`,
      payload
    ),
};
export default NotificationPreferencesAPI;
