import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Space } from "syngenta-digital-cropwise-react-ui-kit";
import { DeckOfApps } from "@cw-elements/deck-of-apps";
import syngentaLogo from "../../../assets/icons/syngenta-logo-provisorio.svg";
import myfieldTitle from "../../../assets/icons/myfield_title.svg";
import type { ApplicationState } from "../../../core/redux/reducers";

const MyFieldLogo = (): JSX.Element => {
  const navigate = useNavigate();
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  return (
    <Space
      align="start"
      className="mf-logo-sec"
      direction="horizontal"
      size="small"
    >
      <DeckOfApps
        onLogout={() => {}}
        headerSelector="dockappoffstyle"
        orgId={profileDataSelector?.defaultOrgId ?? ""}
        propertyId={profileDataSelector?.defaultPropertyId ?? ""}
        workspaceId={
          profileDataSelector?.profileData?.default_workspace_id ?? ""
        }
      />

      <img src={syngentaLogo} className="appLogo mf-mb-10" alt="logoIcon" />
      <Divider
        type="vertical"
        className="mf-mb-5"
        style={{ marginLeft: "-5px" }}
      />
      <button
        className="mf-btn-img mf-height-100 mf-cursor-pointer"
        onClick={() => {
          navigate("/dashboard");
        }}
        style={{ marginLeft: "-10px" }}
      >
        <img src={myfieldTitle} className="appLogo mf-mb-10" alt="logoIcon" />
      </button>
    </Space>
  );
};

export default MyFieldLogo;
