import { createSlice } from "@reduxjs/toolkit";

export interface RecommendationState {
  recommendationList: any;
}
const initialState: RecommendationState = {
  recommendationList: null,
};
export const recommendationSlice = createSlice({
  name: "recommendation stats",
  initialState,
  reducers: {
    setRecommendationList: (state, action) => {
      return {
        ...state,
        recommendationList: action.payload,
      };
    },
  },
});
export const { setRecommendationList } = recommendationSlice.actions;

export default recommendationSlice.reducer;
