import { useSelector } from "react-redux";

import { languageLabels } from "../../../core/constants/constant";
import { type ApplicationState } from "../../../core/redux/reducers";

import arrowIcon from "../../../assets/icons/Iconarrow.svg";

export const RenderLanguageLabel = (): JSX.Element => {
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );
  return (
    <div className="custom-language-menu-title sidebar">
      {languageLabels[homePageState?.currentLanguage as string]}
      <img src={arrowIcon} className="arrow-icon" alt="arrow" />
    </div>
  );
};

export default RenderLanguageLabel;
