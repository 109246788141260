import requests from "./axios";
const UserApi = {
  fetchCurrentUserInfo: async (): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/growers/me`
    ),
  fetchLoyaltiyPoints: async (email: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/loyality/get-point?registeredEmail=${email}`
    ),
};
export default UserApi;
