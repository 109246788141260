import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../core/locale/strings.json";
import { setRefreshUserData } from "../../core/redux/reducers/accountProfileSlice";

import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import synDigitalLogo from "../../assets/images/synDigitalLogo.svg";

import "./errorHandler.less";

export const ErrorHandler = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const goBackToDashboardPage = useCallback(() => {
    dispatch(setRefreshUserData(true));
    navigate("/dashboard");
    window.location.reload();
  }, []);

  return (
    <div className="landing-screen-container">
      <div className="landing-screen-content">
        <img
          className="syn-field-logo"
          src={synMyFieldLogo}
          alt="Syngenta Myfield Logo"
        />
        <div className="login-logout-container">
          <div className="myfield-welcome-message">
            {translate(ConstantKey.SOMETHING_WENT_WRONG)}
          </div>
          <div className="error-handler-signup">
            {translate(ConstantKey.PLEASE_TRY_AFTER_SOME_TIME)}
          </div>
          <button
            className="error-handler-login-btn"
            onClick={() => {
              goBackToDashboardPage();
            }}
          >
            {translate(ConstantKey.GO_BACK_TO_DASHBOARD_PAGE)}
          </button>
        </div>
      </div>
      <div className="syn-field-support">
        {translate(ConstantKey.NEED_HELP)}{" "}
        <a
          className="error-handler-signup-link"
          href="mailto:support@cropwise.com"
        >
          {translate(ConstantKey.SUPPORT_EMAIL)}
        </a>
        <br />
        <img
          className="syn-support-logo"
          src={synDigitalLogo}
          alt="Syngenta Digital Logo"
        />
      </div>
    </div>
  );
};

export default ErrorHandler;
