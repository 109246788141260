import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonType,
  Space,
} from "syngenta-digital-cropwise-react-ui-kit";

export const DeepActionPage = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="mf-pl-10 mf-pt-10">
      <h2 style={{ marginBottom: "20px" }}>Explore Deep action:</h2>
      <Space
        align="start"
        direction="horizontal"
        wrap={true}
        size="small"
        style={{ marginBottom: "20px" }}
      >
        <Button
          type={ButtonType.outline}
          onClick={() => {
            navigate("/create-organization");
          }}
        >
          Create Organization
        </Button>
        <Button
          type={ButtonType.outline}
          onClick={() => {
            navigate("/edit-organization");
          }}
        >
          Edit Organization
        </Button>
        <Button
          type={ButtonType.outline}
          onClick={() => {
            navigate("/create-property");
          }}
        >
          Create Property
        </Button>
        <Button
          type={ButtonType.outline}
          onClick={() => {
            navigate("/edit-property");
          }}
        >
          Edit Property
        </Button>
        <Button
          type={ButtonType.outline}
          onClick={() => {
            navigate("/create-field");
          }}
        >
          Create Field
        </Button>
        <Button
          type={ButtonType.outline}
          onClick={() => {
            navigate("/edit-field");
          }}
        >
          Edit Field
        </Button>
        <Button
          type={ButtonType.outline}
          onClick={() => {
            navigate("/draw-field");
          }}
        >
          Draw Field
        </Button>
      </Space>
    </div>
  );
};

export default DeepActionPage;
