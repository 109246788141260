import { lazy } from "react";
import type { AllRoutesDto } from "../core/types/routes.dto";
import AppRoutes from "./appRoutes";
import AppDashboard from "../pages/dashboard";
import SignInComponent from "../pages/signin";
import SignUpComponent from "../pages/signup";
import DeepActionPage from "../pages/deep-action";
import CreateOrganization from "../pages/deep-action/createOrganization";
import EditOrganization from "../pages/deep-action/editOrganization";
import CreateProperty from "../pages/deep-action/createProperty";
import EditProperty from "../pages/deep-action/editProperty";
import CreateField from "../pages/deep-action/createField";
import EditField from "../pages/deep-action/editField";
import DrawField from "../pages/deep-action/drawField";
import SplashScreen from "../pages/splash-screen";
import SummaryInsights from "../pages/summaryinsights/summaryInsights";
import MyRecommendations from "../pages/myrecommendations/myrecommendations";
import ProductRecommendation from "../pages/product-template/productRecommendation";
import ErrorHandler from "../pages/error-handler";
import LegalDocuments from "../pages/legal-docs";
import VEVPage from "../pages/vev-page";
import Precommitment from "../pages/precommitments";
import ClaimedRedeemedOffers from "../pages/claimed-redeemed-offers";
import MeteoBlueMap from "../pages/meteoblue-map";
import SignUpPassword from "../pages/signup/SignUpPassword";
import SignUpAccountDetails from "../pages/signup/SignUpAccountDetails";
import Preferences from "../pages/preferences";
import SessionSharing from "../pages/session-sharing";
import ManageFields from "../pages/manage-fields";
const DropAPin = lazy(async () => await import("../pages/drop-a-pin"));
const MapView = lazy(async () => await import("../pages/mapView"));

export const routes: AllRoutesDto = {
  openRoutes: [
    {
      path: "/something-went-wrong",
      element: <ErrorHandler />,
      layoutWithoutSideMenu: true,
      layoutWithoutHeader: true,
    },
    {
      path: "/legal-docs",
      element: <LegalDocuments />,
      layoutWithoutSideMenu: true,
      layoutWithoutHeader: true,
    },
  ],
  authRoutes: [
    {
      path: "/splash",
      element: <SplashScreen />,
    },
    {
      path: "/signin",
      element: <SignInComponent />,
    },
    {
      path: "/signup",
      element: <SignUpComponent />,
    },
    {
      path: "/signup-password",
      element: <SignUpPassword />,
    },
    {
      path: "/signup-basic-info",
      element: <SignUpAccountDetails />,
    },
    {
      path: "/session-sharing",
      element: <SessionSharing />,
    },
  ],
  protectedRoutes: [
    {
      path: "/dashboard",
      element: <AppDashboard />,
    },
    {
      path: "/mapview",
      element: <MapView />,
    },
    {
      path: "/metablue-map",
      element: <MeteoBlueMap />,
    },
    {
      path: "/manage-fields",
      element: <ManageFields />,
    },
    {
      path: "/deep-action",
      element: <DeepActionPage />,
    },
    {
      path: "/create-organization",
      element: <CreateOrganization />,
    },
    {
      path: "/edit-organization",
      element: <EditOrganization />,
    },
    {
      path: "/create-property",
      element: <CreateProperty />,
    },
    {
      path: "/edit-property",
      element: <EditProperty />,
    },
    {
      path: "/create-field",
      element: <CreateField />,
    },
    {
      path: "/edit-field",
      element: <EditField />,
    },
    {
      path: "/draw-field",
      element: <DrawField />,
    },
    {
      path: "/drop-a-pin",
      element: <DropAPin />,
      layoutWithoutSideMenu: true,
    },
    {
      path: "/summaryinsights",
      element: <SummaryInsights />,
    },
    {
      path: "/product-recommendations/:id",
      element: <ProductRecommendation />,
    },
    {
      path: "/myrecommendations",
      element: <MyRecommendations />,
    },
    {
      path: "/myrecommendations",
      element: <MyRecommendations />,
    },
    {
      path: "/vev-page/:id",
      element: <VEVPage />,
    },
    {
      path: "/pre-commitments",
      element: <Precommitment />,
    },
    {
      path: "/claimed-redeemed",
      element: <ClaimedRedeemedOffers />,
    },
    {
      path: "/preferences",
      element: <Preferences />,
    },
  ],
};

export const MainRoutes = (): JSX.Element => {
  return <AppRoutes routes={routes}></AppRoutes>;
};

export default MainRoutes;
